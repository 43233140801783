import {
  ICreateReviewRequest,
  IGetReviewsQueryParams,
  IReview,
  IUpdateReviewRequest,
} from '@/types';
import { setSearchParams } from '@/utils/set-search-params';

import { useHeaders } from './useHeaders';

const reviewsEndpoint = '/api/v1/reviews';

export const useReviewsApi = () => {
  const headers = useHeaders();

  const getReviews = async (queryParams: IGetReviewsQueryParams): Promise<IReview[]> => {
    const searchParams = new URLSearchParams();
    setSearchParams(queryParams, searchParams);

    const response = await fetch(`${reviewsEndpoint}?${searchParams.toString()}`, {
      headers,
    });

    const { error, message, reviews } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return reviews;
  };

  const createReview = async (createReviewRequest: ICreateReviewRequest): Promise<IReview> => {
    const { comment, parentId, parentModel, rating } = createReviewRequest;
    const response = await fetch(reviewsEndpoint, {
      method: 'POST',
      body: JSON.stringify({ comment, parentId, parentModel, rating }),
      headers,
    });

    const { error, message, review } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return review;
  };

  const getReviewById = async (id: string): Promise<IReview> => {
    const response = await fetch(`${reviewsEndpoint}/${id}`, {
      headers,
    });

    const { error, message, review } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return review;
  };

  const updateReview = async (updateReviewRequest: IUpdateReviewRequest): Promise<IReview> => {
    const { comment, _id, rating } = updateReviewRequest;
    const response = await fetch(`${reviewsEndpoint}/${_id}`, {
      method: 'PATCH',
      body: JSON.stringify({ comment, rating }),
      headers,
    });

    const { error, message, review } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return review;
  };

  const deleteReview = async (id: string): Promise<void> => {
    const response = await fetch(`${reviewsEndpoint}/${id}`, {
      method: 'DELETE',
      headers,
    });

    const { error, message } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }
  };

  return {
    createReview,
    getReviews,
    deleteReview,
    getReviewById,
    updateReview,
  };
};
