import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TabList, TabPanel } from '@mui/lab';
import TabContext from '@mui/lab/TabContext';
import { Alert, Tab } from '@mui/material';

import Label from '@/components/label';
import { LoadingScreen } from '@/components/loading-screen';
import SearchNotFound from '@/components/search-not-found';
import { useSearch } from '@/hooks/useSearch';

import SearchResults from './SearchResults';

export default function SearchModalTabs({
  closeModal,
  searchTerm,
}: {
  closeModal: VoidFunction;
  searchTerm: string;
}) {
  const { data, error, fetchNextPage, isLoading, isFetchingNextPage, isSuccess } =
    useSearch(searchTerm);

  const [activeTab, setActiveTab] = useState('all');

  const { t } = useTranslation();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  if (isSuccess) {
    return (
      <TabContext value={activeTab}>
        <TabList onChange={(evt, newValue) => setActiveTab(newValue)}>
          <Tab
            iconPosition="end"
            value="all"
            label={t('modals.search.tabs.navigation.all')}
            icon={
              <Label variant={activeTab === 'all' ? 'filled' : 'soft'} color="default">
                {data.pages[0].total}
              </Label>
            }
          />
          <Tab
            iconPosition="end"
            value="vendors"
            label={t('modals.search.tabs.navigation.vendors')}
            icon={
              <Label variant={activeTab === 'vendors' ? 'filled' : 'soft'} color="info">
                {data.pages[0].vendors.count}
              </Label>
            }
          />
          <Tab
            iconPosition="end"
            value="engagements"
            label={t('modals.search.tabs.navigation.engagements')}
            icon={
              <Label variant={activeTab === 'engagements' ? 'filled' : 'soft'} color="warning">
                {data.pages[0].engagements.count}
              </Label>
            }
          />
          <Tab
            iconPosition="end"
            value="contacts"
            label={t('modals.search.tabs.navigation.contacts')}
            icon={
              <Label variant={activeTab === 'contacts' ? 'filled' : 'soft'} color="secondary">
                {data.pages[0].contacts.count}
              </Label>
            }
          />
        </TabList>

        <TabPanel value="all" sx={{ px: 0 }}>
          {data.pages[0].total === 0 && (
            <SearchNotFound
              query={searchTerm}
              subject={t('modals.search.results.defaultSubject')}
            />
          )}
          <SearchResults
            data={data}
            closeModal={closeModal}
            fetchNextPage={fetchNextPage}
            isFetching={isFetchingNextPage}
            showContacts
            showEngagements
            showVendors
          />
        </TabPanel>
        <TabPanel value="vendors" sx={{ px: 0 }}>
          {data.pages[0].vendors.count === 0 && (
            <SearchNotFound
              query={searchTerm}
              subject={t('modals.search.tabs.navigation.vendors')}
            />
          )}
          <SearchResults
            data={data}
            closeModal={closeModal}
            fetchNextPage={fetchNextPage}
            isFetching={isFetchingNextPage}
            showVendors
          />
        </TabPanel>
        <TabPanel value="engagements" sx={{ px: 0 }}>
          {data.pages[0].engagements.count === 0 && (
            <SearchNotFound
              query={searchTerm}
              subject={t('modals.search.tabs.navigation.engagements')}
            />
          )}
          <SearchResults
            data={data}
            closeModal={closeModal}
            fetchNextPage={fetchNextPage}
            isFetching={isFetchingNextPage}
            showEngagements
          />
        </TabPanel>
        <TabPanel value="contacts" sx={{ px: 0 }}>
          {data.pages[0].contacts.count === 0 && (
            <SearchNotFound
              query={searchTerm}
              subject={t('modals.search.tabs.navigation.contacts')}
            />
          )}
          <SearchResults
            data={data}
            closeModal={closeModal}
            fetchNextPage={fetchNextPage}
            isFetching={isFetchingNextPage}
            showContacts
          />
        </TabPanel>
      </TabContext>
    );
  }
}
