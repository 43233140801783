import { useTranslation } from 'react-i18next';

import { Alert } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

import { RHFSelect } from '@/components/hook-form';
import { LoadingScreen } from '@/components/loading-screen';
import { useGetVendors } from '@/hooks/useVendors';

export default function VendorSelect({
  isDisabled,
  autoComplete,
  excludedIds,
  onChange,
}: {
  excludedIds?: string[];
  isDisabled?: boolean;
  autoComplete?: boolean;
  onChange?: (event: React.SyntheticEvent, value: { label: string; value: string } | null) => void;
}) {
  const { t } = useTranslation();

  const { data: { vendors } = {}, error, isLoading, isSuccess } = useGetVendors({ limit: '0' });

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  if (isSuccess) {
    if (autoComplete && onChange) {
      const vendorOptions =
        vendors?.reduce(
          (options, vendor) => {
            const { _id, label } = vendor;
            if (excludedIds?.includes(_id)) {
              return options;
            }
            options.push({
              label,
              value: _id,
            });
            return options;
          },
          [{ label: 'Select', value: '' }],
        ) || [];

      return (
        <Autocomplete
          disablePortal
          id="vendor"
          // blurOnSelect
          // clearOnBlur
          clearOnEscape
          options={vendorOptions}
          onChange={onChange}
          renderInput={(params) => <TextField {...params} fullWidth label="Vendor" />}
          value={null}
        />
      );
    }

    return (
      <RHFSelect
        disabled={isDisabled}
        name="vendor"
        label={t('forms.common.vendor')}
        placeholder={t('forms.common.vendorPlaceholder')}
      >
        {vendors?.map((option) => (
          <MenuItem key={option._id} value={option._id}>
            {option.label}
          </MenuItem>
        ))}
      </RHFSelect>
    );
  }
}
