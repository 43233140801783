import { useTranslation } from 'react-i18next';

import { Alert } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

import { RHFSelect } from '@/components/hook-form';
import { LoadingScreen } from '@/components/loading-screen';
import { useGetUserGroups } from '@/hooks/useUserGroups';

export default function UserGroupSelect() {
  const { data: { userGroups } = {}, error, isLoading, isSuccess } = useGetUserGroups();

  const { t } = useTranslation();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  if (isSuccess) {
    return (
      <RHFSelect
        name="userGroup"
        label={t('forms.common.userGroup')}
        placeholder={t('forms.common.userGroupPlaceholder')}
      >
        {userGroups?.map(({ _id, label }) => (
          <MenuItem key={_id} value={_id}>
            {label}
          </MenuItem>
        ))}
      </RHFSelect>
    );
  }
}
