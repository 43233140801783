import { IFile, IUploadFilesRequest, IUploadFilesResponse } from '@/types';

import { useHeaders } from './useHeaders';

const vendorsEndpoint = '/api/v1/vendors';
export const useFilesApi = () => {
  const headers = useHeaders(false);

  const getVendorFiles = async (id: string): Promise<IFile[]> => {
    const response = await fetch(`${vendorsEndpoint}/${id}/files`, {
      headers,
    });

    const { error, files, message } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return files;
  };

  const uploadVendorFiles = async (
    uploadFilesRequest: IUploadFilesRequest,
  ): Promise<IUploadFilesResponse> => {
    const { formData, vendorId } = uploadFilesRequest;

    const response = await fetch(`${vendorsEndpoint}/${vendorId}/files`, {
      method: 'POST',
      body: formData,
      headers,
    });

    const { error, files: uploadedFiles, message } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return { files: uploadedFiles, vendorId };
  };

  const getVendorFileById = async ({
    vendorId,
    fileId,
  }: {
    vendorId: string;
    fileId: string;
  }): Promise<IFile> => {
    const response = await fetch(`${vendorsEndpoint}/${vendorId}/files/${fileId}`, {
      headers,
    });

    const { error, files, message } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return files;
  };

  const deleteVendorFile = async ({
    vendorId,
    fileId,
  }: {
    vendorId: string;
    fileId: string;
  }): Promise<IFile> => {
    const response = await fetch(`${vendorsEndpoint}/${vendorId}/files/${fileId}`, {
      method: 'DELETE',
      headers,
    });

    const { error, file, message } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return file;
  };

  return {
    getVendorFiles,
    getVendorFileById,
    uploadVendorFiles,
    deleteVendorFile,
  };
};
