import { PermissionAction, PermissionHandle } from '@/constants';

// TODO: MOVE To CONSTANTS
export enum Status {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum StatusOptionTypeEnum {
  ENGAGEMENT = 'ENGAGEMENT',
  VENDOR = 'VENDOR',
}

export interface IPermission {
  action: PermissionAction;
  handle: PermissionHandle;
}

interface IUserGroup {
  _id: string;
  label: string;
  organization: string;
  permissions: IPermission[];
}

export interface IStatusOption {
  _id: string;
  statusType: string;
  label: string;
  organization: string;
  __v: number;
}

interface ISubscriptionFeature {
  _id: string;
  feature: {
    _id: string;
    description: string;
    handle: string;
    label: string;
  };
  limit: number;
}

export interface ISubscriptionPlan {
  _id: string;
  features: ISubscriptionFeature[];
  isDefault: boolean;
  label: string;
  price: number;
  status: string;
}

export interface ISubscription {
  _id: string;
  // TODO: UPDATE WHEN LIVE IS READY
  features: {
    _id?: string;
    description?: string;
    handle: string;
    label?: string;
  }[];
  subscriptionPlan: ISubscriptionPlan;
}

export interface IOrganization {
  _id: string;
  label: string;
  status: Status;
  statusOptions: IStatusOption[];
  subscription: ISubscription;
}

export interface ICreateOrganizationRequest {
  label: string;
}

export interface ITag {
  _id: string;
  value: string;
}

export interface IGetTagsResponse {
  tags: ITag[];
  tagsMap: Map<string, string>;
}

export interface IUser {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  status: Status;
  userGroups: IUserGroup[];
  organizations: IOrganization[];
}

export interface IGetUsersQueryParams {
  [key: string]: string | undefined;
  limit?: string;
  status?: string;
  page?: string;
}

export interface IGetUsersResponse {
  totalResults: number;
  users: IUser[];
}

export interface IReview {
  _id: string;
  comment: string;
  createdAt: string;
  createdBy: IUser;
  rating: number;
  updatedAt: string;
  parentId: string;
}

export interface ICreateReviewRequest {
  comment?: string;
  rating: number;
  parentId: string;
  parentModel: 'Vendor' | 'Engagement';
}

export interface IUpdateReviewRequest
  extends Omit<ICreateReviewRequest, 'parentId' | 'parentModel'> {
  _id: string;
}

export interface IVendorFields {
  label: string;
  status: string;
  tags: { label: string; value: string }[];
}

export interface ICreateVendorRequest {
  label: string;
  status: string;
  tags?: string[];
}

export interface IUpdateVendorRequest extends ICreateVendorRequest {
  _id: string;
}

interface IPhoneNumber {
  _id?: string | undefined;
  number?: string;
  numberType?: string;
}

export type IContactBase = {
  _id: string;
  avatarUrl?: string;
  email: string;
  firstName: string;
  lastName: string;
  organization: string;
  phoneNumbers?: IPhoneNumber[];
  title?: string;
  vendor: string;
};

export interface IVendor {
  _id: string;
  active: boolean;
  contacts?: Pick<IContactBase, '_id' | 'avatarUrl' | 'firstName' | 'lastName' | 'vendor'>[];
  createdAt: string;
  // TODO: DITCH
  createdBy: IUser;
  engagements?: { _id: string; label: string; status: IStatusOption }[];
  label: string;
  organization: string;
  rating: number;
  status: IStatusOption;
  tags: ITag[];
  updatedAt: string;
  __v: number;
}

export interface IGetVendorsResponse {
  vendors: IVendor[];
  totalResults: number;
}

export interface IGetVendorsQueryParams {
  [key: string]: string | undefined;
  limit?: string;
  status?: string;
  page?: string;
}

export interface ISession {
  error?: boolean;
  currentSession: {
    access: string;
    sessionId: string;
    token: string;
    _id: string;
  };
  user: IUser;
}

export interface ICreateSessionRequest {
  email: string;
  password: string;
}

export interface ICreateUserRequest {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
}

export interface IUpdateUserRequest {
  _id: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  password?: string;
}

export type ICreateUserResponse = Omit<ISession, 'error'>;

export interface IEngagement {
  _id: string;
  createdAt: string;
  createdBy: IUser;
  description?: string;
  frequency?: string;
  label: string;
  organization: string;
  status: IStatusOption;
  type: string;
  updatedAt: string;
  vendor: IVendor;
}

export interface IGetEngagementsResponse {
  engagements: IEngagement[];
  totalResults: number;
}

export interface IGetEngagementsQueryParams {
  [key: string]: string | undefined;
  limit?: string;
  page?: string;
  status?: string;
  type?: string;
  vendor?: string;
}

export interface IMutateEngagementRequest {
  _id?: string;
  description?: string;
  frequency?: string;
  label: string;
  status: string;
  type: string;
  vendor: string;
}

export type IContactResponse = IContactBase & {
  engagements?: Pick<IEngagement, '_id' | 'label'>[];
  vendor: Pick<IVendor, '_id' | 'label'>;
};

export type IMutateContactRequest = Omit<IContactBase, '_id'> & {
  _id?: string | undefined;
  engagements?: string[];
  organization?: string | undefined;
};

export interface ICreateContactRequest {
  avatarUrl?: string;
  email?: string;
  engagements?: string[];
  firstName: string;
  lastName: string;
  phoneNumbers?: IPhoneNumber[];
  title?: string;
  vendor: string;
}

export interface IUpdateContactRequest extends ICreateContactRequest {
  _id: string;
}

export interface IGetContactsResponse {
  contacts: IContactResponse[];
  totalResults: number;
}

export interface IGetContactsQueryParams {
  [key: string]: string | undefined;
  engagements?: string;
  limit?: string;
  organization?: string;
  page?: string;
  vendor?: string;
}

export interface INote {
  _id: string;
  comment: string;
  createdAt: string;
  createdBy: IUser;
  parentId: string;
}

export interface ICreateNoteRequest {
  comment: string;
  parentId: string;
  parentModel: 'Contact' | 'Engagement' | 'Prospect' | 'Vendor';
}

export interface IUpdateNoteRequest {
  _id: string;
  comment: string;
}

export interface IGetInvitationsResponse {
  invitations: IInvitation[];
  totalResults: number;
}

export interface IInvitation {
  _id: string;
  invitedBy: {
    firstName: string;
    lastName: string;
  };
  organization: IOrganization;
  recipient: string;
  status: string;
  userGroup: {
    _id: string;
    label: string;
  };
}

export interface IMutateInvitation {
  id?: string;
  email?: string;
  userGroup?: string;
}

export interface IGetUserGroupsResponse {
  userGroups: IUserGroup[];
}

export interface UpdateInvitationResponse {
  invitation: IInvitation;
  user: IUser;
}

export interface IFile {
  _id: string;
  extension: string;
  fileSize: string;
  label: string;
  mimeType: string;
  organization: string;
  uploadedBy: Pick<IUser, 'email' | 'firstName' | 'lastName'>;
  url: string;
  vendor: string;
}

export interface IUploadFilesRequest {
  formData: FormData;
  vendorId: string;
}

export interface IUploadFilesResponse {
  files: IFile[];
  vendorId: string;
}

export interface IGetProspectsQueryParams {
  [key: string]: string | undefined;
  limit?: string;
  status?: string;
  page?: string;
}

export enum ProspectStatusEnum {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export enum ProspectVendorStatusEnum {
  CANDIDATE = 'CANDIDATE',
  IN_REVIEW = 'IN_REVIEW',
  SELECTED = 'SELECTED',
}

export interface IProspectVendor {
  _id: string;
  status: ProspectVendorStatusEnum;
  vendor: IVendor;
}

export interface IProspectBoardItemMap {
  [key: string]: (IProspectVendor & { id: string })[];
}

export interface IProspect {
  _id: string;
  label: string;
  organization: string;
  status: ProspectStatusEnum;
  vendors: IProspectVendor[];
}

export interface IGetProspectsResponse {
  prospects: IProspect[];
  totalResults: number;
}

export interface ICreateProspect {
  label: string;
}

export interface IUpdateProspectVendor {
  label?: string;
  status?: ProspectVendorStatusEnum;
  vendor?: IVendor | string;
}

export interface IUpdateProspect {
  _id: string;
  label?: string;
  status?: ProspectStatusEnum;
  vendors?: IUpdateProspectVendor[];
}

interface IHighlightMatch {
  value: string;
}

interface ISearchMatch {
  [key: string]: IHighlightMatch | IHighlightMatch[];
}

export interface ISearchResult {
  firstName?: string;
  email?: string;
  label?: string;
  lastName?: string;
  objectID: string;
  matches: ISearchMatch;
  title?: string;
  vendor?: string;
}

interface ISearchIndexResult {
  count: number;
  index: string;
  page: number;
  pages: number;
  results: ISearchResult[];
}

export interface ISearchResponse {
  contacts: ISearchIndexResult;
  engagements: ISearchIndexResult;
  total: number;
  vendors: ISearchIndexResult;
}

export interface IGetSubscriptionPlansQueryParams {
  [key: string]: string | undefined;
  limit?: string;
  status?: string;
  page?: string;
}

export interface IGetSubscriptionPlansResponse {
  subscriptionPlans: ISubscriptionPlan[];
  totalResults: number;
}

export interface ICreatePaymentMethod {
  isDefault: boolean;
  orgId: string;
  paymentMethodId: string;
}

export interface IPaymentMethod {
  id: string;
  brand: string;
  expMonth: number;
  expYear: number;
  isDefault: boolean;
  last4: number;
}

export interface ISubscriptionPreview {
  amountDueToday: number;
  nextBillingTimestamp: number;
  recurringAmount: number;
}

export interface IGetReviewsQueryParams {
  [key: string]: string;
  parentId: string;
}

export interface IGetNotesQueryParams {
  [key: string]: string;
  parentId: string;
}
