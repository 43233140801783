import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import posthog from 'posthog-js';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { SplashScreen } from '@/components/loading-screen';
import ProgressBar from '@/components/progress-bar';
import { SettingsDrawer, SettingsProvider } from '@/components/settings';
import SnackbarProvider from '@/components/snackbar/snackbar-provider';
import { AppContextProvider } from '@/providers';
import AppRoutes from '@/routes';
import ThemeProvider from '@/theme';

import './i18n';

// This env var should only be passed to prod deployments
if (import.meta.env.VITE_POSTHOG_API_KEY) {
  posthog.init(import.meta.env.VITE_POSTHOG_API_KEY, {
    api_host: 'https://us.posthog.com',
    // Avoid all autocapture
    autocapture: false,
  });
}

const queryClient = new QueryClient({
  queryCache: new QueryCache(),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const rootElement = document.getElementById('root');

if (!rootElement) {
  throw new Error('Could not find root!');
}

const root = createRoot(rootElement);

root.render(
  // <React.StrictMode>
  <HelmetProvider>
    <AppContextProvider>
      <SettingsProvider
        defaultSettings={{
          themeMode: 'light', // 'light' | 'dark'
          themeContrast: 'default', // 'default' | 'bold'
          themeLayout: 'vertical', // 'vertical' | 'mini'
          themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
          themeStretch: true,
        }}
      >
        <ThemeProvider>
          <BrowserRouter>
            <Suspense fallback={<SplashScreen />}>
              <QueryClientProvider client={queryClient}>
                <SnackbarProvider>
                  <SettingsDrawer />
                  <ProgressBar />
                  <AppRoutes />
                </SnackbarProvider>
              </QueryClientProvider>
            </Suspense>
          </BrowserRouter>
        </ThemeProvider>
      </SettingsProvider>
    </AppContextProvider>
  </HelmetProvider>,
  // </React.StrictMode>,
);
