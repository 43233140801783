import type { ICreateOrganizationRequest, IOrganization, IUser } from '@/types';

import { useHeaders } from './useHeaders';

const organizationsEndpoint = `/api/v1/organizations`;

export const useOrganizationApi = () => {
  const headers = useHeaders();

  const getOrganization = async (id: string) => {
    const response = await fetch(`${organizationsEndpoint}/${id}`, {
      headers,
    });

    const { error, message, organization } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return organization;
  };

  const createOrganization = async (
    createOrganizationRequest: ICreateOrganizationRequest,
  ): Promise<IOrganization> => {
    const response = await fetch(organizationsEndpoint, {
      method: 'POST',
      body: JSON.stringify(createOrganizationRequest),
      headers,
    });

    const { error, message, organization } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return organization;
  };

  const updateUser = async ({
    orgId,
    userId,
    userGroupId,
  }: {
    orgId: string;
    userId: string;
    userGroupId: string;
  }): Promise<IUser> => {
    const response = await fetch(
      `${organizationsEndpoint}/${orgId}/users/${userId}/user-group/${userGroupId}`,
      {
        method: 'PATCH',
        headers,
      },
    );

    const { error, message, user } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return user;
  };

  const removeUser = async ({
    orgId,
    userId,
  }: {
    orgId: string;
    userId: string;
  }): Promise<IUser> => {
    const response = await fetch(`${organizationsEndpoint}/${orgId}/users/${userId}`, {
      method: 'DELETE',
      headers,
    });

    const { errors, user } = await response.json();

    if (errors) {
      const { msg } = errors[0];
      throw new Error(`${response.status}: ${msg}`);
    }

    return user;
  };

  return {
    createOrganization,
    getOrganization,
    updateUser,
    removeUser,
  };
};
