import Cookies from 'js-cookie';

import { TOKEN_KEY } from '@/constants';
import { useAppContext } from '@/providers';

export const useHeaders = (includeContentType = true) => {
  const { currentOrganization, session } = useAppContext();
  const token = session?.currentSession.token || Cookies.get(TOKEN_KEY);

  const baseHeaders = {
    Authorization: `Bearer ${token}`,
    'x-org-id': currentOrganization?.id,
  };

  return new Headers(
    includeContentType
      ? {
          ...baseHeaders,
          'Content-Type': 'application/json',
        }
      : baseHeaders,
  );
};
