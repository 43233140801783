/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateSubscriptionPlanRequest,
  ErrorResponse,
  SubscriptionPlanPageResponse,
  SubscriptionPlanResponse,
} from '../models/index';
import {
    CreateSubscriptionPlanRequestFromJSON,
    CreateSubscriptionPlanRequestToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    SubscriptionPlanPageResponseFromJSON,
    SubscriptionPlanPageResponseToJSON,
    SubscriptionPlanResponseFromJSON,
    SubscriptionPlanResponseToJSON,
} from '../models/index';

export interface CreateSubscriptionPlanOperationRequest {
    createSubscriptionPlanRequest: CreateSubscriptionPlanRequest;
}

export interface GetActiveSubscriptionPlansRequest {
    page?: number;
    size?: number;
}

export interface GetSubscriptionPlanByIdRequest {
    id: string;
}

/**
 * 
 */
export class SubscriptionPlansApi extends runtime.BaseAPI {

    /**
     * Create a new Subscription Plan
     */
    async createSubscriptionPlanRaw(requestParameters: CreateSubscriptionPlanOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubscriptionPlanResponse>> {
        if (requestParameters['createSubscriptionPlanRequest'] == null) {
            throw new runtime.RequiredError(
                'createSubscriptionPlanRequest',
                'Required parameter "createSubscriptionPlanRequest" was null or undefined when calling createSubscriptionPlan().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/subscription-plans`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSubscriptionPlanRequestToJSON(requestParameters['createSubscriptionPlanRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionPlanResponseFromJSON(jsonValue));
    }

    /**
     * Create a new Subscription Plan
     */
    async createSubscriptionPlan(requestParameters: CreateSubscriptionPlanOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubscriptionPlanResponse> {
        const response = await this.createSubscriptionPlanRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all active Subscription Plans
     */
    async getActiveSubscriptionPlansRaw(requestParameters: GetActiveSubscriptionPlansRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubscriptionPlanPageResponse>> {
        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/subscription-plans/active`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionPlanPageResponseFromJSON(jsonValue));
    }

    /**
     * Get all active Subscription Plans
     */
    async getActiveSubscriptionPlans(requestParameters: GetActiveSubscriptionPlansRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubscriptionPlanPageResponse> {
        const response = await this.getActiveSubscriptionPlansRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Subscription Plan by id
     */
    async getSubscriptionPlanByIdRaw(requestParameters: GetSubscriptionPlanByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubscriptionPlanResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getSubscriptionPlanById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/subscription-plans/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionPlanResponseFromJSON(jsonValue));
    }

    /**
     * Get Subscription Plan by id
     */
    async getSubscriptionPlanById(requestParameters: GetSubscriptionPlanByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubscriptionPlanResponse> {
        const response = await this.getSubscriptionPlanByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
