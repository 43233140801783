/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateFeatureRequest
 */
export interface CreateFeatureRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateFeatureRequest
     */
    handle: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFeatureRequest
     */
    label: string;
    /**
     * 
     * @type {number}
     * @memberof CreateFeatureRequest
     */
    limit: number;
}

/**
 * Check if a given object implements the CreateFeatureRequest interface.
 */
export function instanceOfCreateFeatureRequest(value: object): value is CreateFeatureRequest {
    if (!('handle' in value) || value['handle'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('limit' in value) || value['limit'] === undefined) return false;
    return true;
}

export function CreateFeatureRequestFromJSON(json: any): CreateFeatureRequest {
    return CreateFeatureRequestFromJSONTyped(json, false);
}

export function CreateFeatureRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateFeatureRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'handle': json['handle'],
        'label': json['label'],
        'limit': json['limit'],
    };
}

export function CreateFeatureRequestToJSON(value?: CreateFeatureRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'handle': value['handle'],
        'label': value['label'],
        'limit': value['limit'],
    };
}

