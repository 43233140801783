import { IGetSubscriptionPlansQueryParams } from '@/types';
import { setSearchParams } from '@/utils/set-search-params';

import { useHeaders } from './useHeaders';

const subscriptionsEndpoint = '/api/v1/subscription-plans';

export const useSubscriptionPlansApi = () => {
  const headers = useHeaders();

  const getSubscriptionPlans = async (queryParams?: IGetSubscriptionPlansQueryParams) => {
    const searchParams = new URLSearchParams();
    setSearchParams(queryParams, searchParams);

    const url =
      searchParams.size === 0
        ? subscriptionsEndpoint
        : `${subscriptionsEndpoint}?${searchParams.toString()}`;

    const response = await fetch(url, {
      headers,
    });

    const { error, message, subscriptionPlans, totalResults } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return { subscriptionPlans, totalResults };
  };

  const getSubscriptionPlanById = async (id: string) => {
    const response = await fetch(`${subscriptionsEndpoint}/${id}`, {
      headers,
    });

    const { error, message, subscriptionPlan } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return subscriptionPlan;
  };

  return {
    getSubscriptionPlanById,
    getSubscriptionPlans,
  };
};
