import Cookies from 'js-cookie';

import { TOKEN_KEY } from '@/constants';
import type { ISession } from '@/types';

import { useHeaders } from './useHeaders';

const sessionsEndpoint = `/api/v1/sessions`;

export const updateSession = async (token: string) => {
  const response = await fetch(sessionsEndpoint, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  const { currentSession, error, message, user } = await response.json();

  if (error) {
    throw new Error(`${response.status} - ${message}`);
  }

  return { currentSession, error, user };
};

export const useSessionsApi = () => {
  const headers = useHeaders();
  const getSession = async (): Promise<ISession> => {
    const response = await fetch(sessionsEndpoint, {
      headers,
    });

    const { currentSession, error, message, user } = await response.json();

    if (error) {
      Cookies.remove(TOKEN_KEY);
      throw new Error(message);
    }

    return { currentSession, error, user };
  };

  return { getSession };
};
