import { useQuery } from '@tanstack/react-query';

import { useUserGroupsApi } from '@/api';
import { useAppContext } from '@/providers';
import { IGetUserGroupsResponse } from '@/types';

export const QUERY_KEY = (orgId: string) => ['user-groups', orgId];

export const useGetUserGroups = () => {
  const { getUserGroups } = useUserGroupsApi();
  const { currentOrganization } = useAppContext();
  return useQuery<IGetUserGroupsResponse, Error>({
    queryKey: QUERY_KEY(currentOrganization.id),
    queryFn: () => getUserGroups(),
  });
};
