import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useFilesApi } from '@/api';
import { useError } from '@/hooks/useError';
import { IFile, IUploadFilesRequest, IUploadFilesResponse } from '@/types';

const QUERY_KEY = (vendorId: string, id?: string) => ['vendor-files', vendorId, id];

export const useGetVendorFiles = (vendorId: string) => {
  const { getVendorFiles } = useFilesApi();
  return useQuery<IFile[], Error>({
    queryKey: QUERY_KEY(vendorId),
    queryFn: () => getVendorFiles(vendorId),
  });
};
//
// export const useGetVendorFileById = (
//   { vendorId, fileId }: { vendorId: string; fileId: string },
//   queryOptions?: UseQueryOptions<IFile, Error>,
// ) => {
//   const { getVendorFileById } = useFilesApi();
//   return useQuery<IFile, Error>(
//     QUERY_KEY(vendorId, fileId),
//     () => getVendorFileById({ vendorId, fileId }),
//     { ...queryOptions },
//   );
// };
//
export const useUploadVendorFiles = () => {
  const { uploadVendorFiles } = useFilesApi();
  const { reportError } = useError();
  const queryClient = useQueryClient();
  return useMutation<IUploadFilesResponse, Error, IUploadFilesRequest>({
    mutationFn: ({ formData, vendorId }) => uploadVendorFiles({ formData, vendorId }),
    onError: (error) => reportError(error),
    onSuccess: ({ vendorId }) => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEY(vendorId) });
    },
  });
};
//
// export const useDeleteVendorFile = () => {
//   const { deleteVendorFile } = useFilesApi();
//   const queryClient = useQueryClient();
//   return useMutation<IFile, Error, { vendorId: string; fileId: string }>(
//     ({ vendorId, fileId }) => deleteVendorFile({ vendorId, fileId }),
//     {
//       onSuccess: ({ vendor }) => {
//         queryClient.invalidateQueries(QUERY_KEY(vendor));
//       },
//     },
//   );
// };
