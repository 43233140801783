import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { RHFSelect, RHFTextField } from '@/components/hook-form';

import VendorSelect from './VendorSelect';

export default function EngagementFields({ showVendorSelect }: { showVendorSelect: boolean }) {
  // const { data: organization, error, isLoading, isSuccess } = useGetOrganization();

  // const statusOptions = useStatusOptions(
  //   organization?.statusOptions || [],
  //   StatusOptionTypeEnum.ENGAGEMENT,
  // );

  const { t } = useTranslation();

  const { setValue, watch } = useFormContext();

  const watchType = watch('type');

  // if (isLoading) {
  //   return <LoadingScreen />;
  // }
  //
  // if (error) {
  //   return <Alert severity="error">{error.message}</Alert>;
  // }
  //
  // if (isSuccess) {
  return (
    <Stack spacing={2}>
      <Controller
        render={({ field }) => (
          <Box>
            <ToggleButtonGroup
              color="primary"
              value={field.value}
              exclusive
              onChange={(evt, value) => {
                if (value === 'ONE-TIME') {
                  setValue('frequency', '');
                }

                if (value) {
                  field.onChange(value);
                }
              }}
              aria-label="Type"
            >
              <ToggleButton value="ONE-TIME">One Time</ToggleButton>
              <ToggleButton value="RECURRING">Recurring</ToggleButton>
            </ToggleButtonGroup>
          </Box>
        )}
        name="type"
      />
      {watchType === 'RECURRING' && (
        <RHFSelect
          name="frequency"
          label={t('forms.engagements.fields.frequency')}
          placeholder={t('forms.engagements.fields.frequencyPlaceholder')}
        >
          <MenuItem value="DAILY">Daily</MenuItem>
          <MenuItem value="WEEKLY">Weekly</MenuItem>
          <MenuItem value="BI-WEEKLY">Bi-Weekly</MenuItem>
          <MenuItem value="MONTHLY">Monthly</MenuItem>
          <MenuItem value="ANNUALLY">Annually</MenuItem>
        </RHFSelect>
      )}
      {!showVendorSelect && <VendorSelect />}
      <RHFTextField name="label" label={t('forms.engagements.fields.label')} />
      {/* <RHFSelect */}
      {/*   name="status" */}
      {/*   label={t('forms.engagements.fields.status')} */}
      {/*   placeholder={t('forms.engagements.fields.statusPlaceholder')} */}
      {/* > */}
      {/*   {statusOptions.map((option) => ( */}
      {/*     <MenuItem key={option._id} value={option._id}> */}
      {/*       {option.label} */}
      {/*     </MenuItem> */}
      {/*   ))} */}
      {/* </RHFSelect> */}
      <RHFTextField name="description" label="Description" multiline minRows={3} />
    </Stack>
  );
  // }
}
