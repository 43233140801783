/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateVendorRequest,
  ErrorResponse,
  VendorPageResponse,
  VendorResponse,
} from '../models/index';
import {
    CreateVendorRequestFromJSON,
    CreateVendorRequestToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    VendorPageResponseFromJSON,
    VendorPageResponseToJSON,
    VendorResponseFromJSON,
    VendorResponseToJSON,
} from '../models/index';

export interface CreateVendorOperationRequest {
    createVendorRequest: CreateVendorRequest;
}

export interface GetVendorRequest {
    id: string;
}

export interface GetVendorsRequest {
    xOrganizationId?: string;
    page?: number;
    size?: number;
}

export interface UpdateVendorRequest {
    id: string;
    requestBody: { [key: string]: object; };
}

/**
 * 
 */
export class VendorsApi extends runtime.BaseAPI {

    /**
     * Create a new Vendor
     */
    async createVendorRaw(requestParameters: CreateVendorOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VendorResponse>> {
        if (requestParameters['createVendorRequest'] == null) {
            throw new runtime.RequiredError(
                'createVendorRequest',
                'Required parameter "createVendorRequest" was null or undefined when calling createVendor().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vendors`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateVendorRequestToJSON(requestParameters['createVendorRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VendorResponseFromJSON(jsonValue));
    }

    /**
     * Create a new Vendor
     */
    async createVendor(requestParameters: CreateVendorOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VendorResponse> {
        const response = await this.createVendorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a Vendor by ID
     */
    async getVendorRaw(requestParameters: GetVendorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VendorResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getVendor().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vendors/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VendorResponseFromJSON(jsonValue));
    }

    /**
     * Get a Vendor by ID
     */
    async getVendor(requestParameters: GetVendorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VendorResponse> {
        const response = await this.getVendorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of vendors
     */
    async getVendorsRaw(requestParameters: GetVendorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VendorPageResponse>> {
        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['xOrganizationId'] != null) {
            headerParameters['X-Organization-Id'] = String(requestParameters['xOrganizationId']);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vendors`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VendorPageResponseFromJSON(jsonValue));
    }

    /**
     * Get a list of vendors
     */
    async getVendors(requestParameters: GetVendorsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VendorPageResponse> {
        const response = await this.getVendorsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a Vendor
     */
    async updateVendorRaw(requestParameters: UpdateVendorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VendorResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateVendor().'
            );
        }

        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling updateVendor().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vendors/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VendorResponseFromJSON(jsonValue));
    }

    /**
     * Update a Vendor
     */
    async updateVendor(requestParameters: UpdateVendorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VendorResponse> {
        const response = await this.updateVendorRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
