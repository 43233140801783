import {
  ICreateProspect,
  IGetProspectsQueryParams,
  IGetProspectsResponse,
  IProspect,
  IUpdateProspect,
} from '@/types';
import { setSearchParams } from '@/utils/set-search-params';

import { useHeaders } from './useHeaders';

const prospectsUrl = '/api/v1/prospects';
export const useProspectsApi = () => {
  const headers = useHeaders();

  const getProspects = async (
    queryParams?: IGetProspectsQueryParams,
  ): Promise<IGetProspectsResponse> => {
    const searchParams = new URLSearchParams();
    setSearchParams(queryParams, searchParams);

    const url =
      searchParams.size === 0 ? prospectsUrl : `${prospectsUrl}?${searchParams.toString()}`;

    const response = await fetch(url, {
      headers,
    });

    const { error, message, prospects, totalResults } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return { totalResults, prospects };
  };

  const getProspectById = async (id: string): Promise<IProspect> => {
    const response = await fetch(`${prospectsUrl}/${id}`, {
      headers,
    });

    const { error, message, prospect } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return prospect;
  };

  const createProspect = async ({ label }: ICreateProspect): Promise<IProspect> => {
    const response = await fetch(prospectsUrl, {
      headers,
      method: 'POST',
      body: JSON.stringify({ label }),
    });

    const { error, message, prospect } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return prospect;
  };

  const updateProspect = async (updatedProspect: IUpdateProspect): Promise<IProspect> => {
    const { _id, label, status, vendors } = updatedProspect;
    const response = await fetch(`${prospectsUrl}/${_id}`, {
      headers,
      method: 'PATCH',
      body: JSON.stringify({ label, status, vendors }),
    });

    const { error, message, prospect } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return prospect;
  };

  const deleteProspect = async (id: string): Promise<IProspect> => {
    const response = await fetch(`${prospectsUrl}/${id}`, {
      headers,
      method: 'DELETE',
    });

    const { error, message, prospect } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return prospect;
  };

  return {
    getProspects,
    getProspectById,
    createProspect,
    updateProspect,
    deleteProspect,
  };
};
