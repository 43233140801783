/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateVendorRequest
 */
export interface CreateVendorRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateVendorRequest
     */
    label: string;
}

/**
 * Check if a given object implements the CreateVendorRequest interface.
 */
export function instanceOfCreateVendorRequest(value: object): value is CreateVendorRequest {
    if (!('label' in value) || value['label'] === undefined) return false;
    return true;
}

export function CreateVendorRequestFromJSON(json: any): CreateVendorRequest {
    return CreateVendorRequestFromJSONTyped(json, false);
}

export function CreateVendorRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateVendorRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'label': json['label'],
    };
}

export function CreateVendorRequestToJSON(value?: CreateVendorRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'label': value['label'],
    };
}

