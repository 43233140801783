import { IGetTagsResponse } from '@/types';

import { useHeaders } from './useHeaders';

const tagsEndpoint = `/api/v1/tags`;

export const useTagsApi = () => {
  const headers = useHeaders();

  const getTags = async (): Promise<IGetTagsResponse> => {
    const response = await fetch(tagsEndpoint, {
      headers,
    });

    const { error, message, tags } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    const tagsMap = new Map<string, string>();
    tags.map(({ _id, value }: { _id: string; value: string }) => tagsMap.set(value, _id));

    return { tags, tagsMap };
  };

  return { getTags };
};
