import { InfiniteData } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';

import Stack from '@mui/material/Stack';

import Iconify from '@/components/iconify';
import Label from '@/components/label';
import { LoadingScreen } from '@/components/loading-screen';
import { ISearchResponse } from '@/types';

import Result from './Result';

export default function SearchResults({
  closeModal,
  data,
  fetchNextPage,
  isFetching,
  showContacts,
  showEngagements,
  showVendors,
}: {
  closeModal: VoidFunction;
  data: InfiniteData<ISearchResponse>;
  fetchNextPage: VoidFunction;
  isFetching: boolean;
  showContacts?: boolean;
  showEngagements?: boolean;
  showVendors?: boolean;
}) {
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  const navigate = useNavigate();

  const { t } = useTranslation();
  return (
    <Stack spacing={2}>
      {data?.pages.map(({ contacts, engagements, vendors }, index) => (
        <Stack key={`results-${index}`} spacing={2}>
          {showVendors &&
            vendors.results.map((result) => (
              <Result
                key={result.objectID}
                onClick={() => {
                  closeModal();
                  navigate(`/${result.objectID}`);
                }}
                result={result}
              >
                <Label color="info" startIcon={<Iconify width={16} icon="mdi:store" />}>
                  {t('modals.search.results.vendor')}
                </Label>
              </Result>
            ))}
          {showEngagements &&
            engagements.results.map((result) => (
              <Result
                key={result.objectID}
                onClick={() => {
                  closeModal();
                  navigate(`/engagements/${result.objectID}`);
                }}
                result={result}
              >
                <Label
                  color="warning"
                  startIcon={<Iconify width={16} icon="mdi:briefcase-outline" />}
                >
                  {t('modals.search.results.engagement')}
                </Label>
              </Result>
            ))}
          {showContacts &&
            contacts.results.map((result) => (
              <Result
                key={result.objectID}
                onClick={() => {
                  closeModal();
                  navigate(`/contacts/${result.objectID}`);
                }}
                result={result}
              >
                <Label color="secondary" startIcon={<Iconify width={16} icon="mdi:contact" />}>
                  {t('modals.search.results.contact')}
                </Label>
              </Result>
            ))}
        </Stack>
      ))}
      <div ref={ref}>{isFetching && <LoadingScreen />}</div>
    </Stack>
  );
}
