/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PreviewSubscriptionResponse
 */
export interface PreviewSubscriptionResponse {
    /**
     * 
     * @type {number}
     * @memberof PreviewSubscriptionResponse
     */
    amountDue: number;
    /**
     * 
     * @type {number}
     * @memberof PreviewSubscriptionResponse
     */
    nextPaymentAttempt?: number;
    /**
     * 
     * @type {number}
     * @memberof PreviewSubscriptionResponse
     */
    recurringAmount: number;
}

/**
 * Check if a given object implements the PreviewSubscriptionResponse interface.
 */
export function instanceOfPreviewSubscriptionResponse(value: object): value is PreviewSubscriptionResponse {
    if (!('amountDue' in value) || value['amountDue'] === undefined) return false;
    if (!('recurringAmount' in value) || value['recurringAmount'] === undefined) return false;
    return true;
}

export function PreviewSubscriptionResponseFromJSON(json: any): PreviewSubscriptionResponse {
    return PreviewSubscriptionResponseFromJSONTyped(json, false);
}

export function PreviewSubscriptionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreviewSubscriptionResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'amountDue': json['amountDue'],
        'nextPaymentAttempt': json['nextPaymentAttempt'] == null ? undefined : json['nextPaymentAttempt'],
        'recurringAmount': json['recurringAmount'],
    };
}

export function PreviewSubscriptionResponseToJSON(value?: PreviewSubscriptionResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'amountDue': value['amountDue'],
        'nextPaymentAttempt': value['nextPaymentAttempt'],
        'recurringAmount': value['recurringAmount'],
    };
}

