/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SubscriptionPlanResponse } from './SubscriptionPlanResponse';
import {
    SubscriptionPlanResponseFromJSON,
    SubscriptionPlanResponseFromJSONTyped,
    SubscriptionPlanResponseToJSON,
} from './SubscriptionPlanResponse';

/**
 * 
 * @export
 * @interface SubscriptionPlanPageResponse
 */
export interface SubscriptionPlanPageResponse {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionPlanPageResponse
     */
    totalCount: number;
    /**
     * 
     * @type {Array<SubscriptionPlanResponse>}
     * @memberof SubscriptionPlanPageResponse
     */
    subscriptionPlans: Array<SubscriptionPlanResponse>;
}

/**
 * Check if a given object implements the SubscriptionPlanPageResponse interface.
 */
export function instanceOfSubscriptionPlanPageResponse(value: object): value is SubscriptionPlanPageResponse {
    if (!('totalCount' in value) || value['totalCount'] === undefined) return false;
    if (!('subscriptionPlans' in value) || value['subscriptionPlans'] === undefined) return false;
    return true;
}

export function SubscriptionPlanPageResponseFromJSON(json: any): SubscriptionPlanPageResponse {
    return SubscriptionPlanPageResponseFromJSONTyped(json, false);
}

export function SubscriptionPlanPageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionPlanPageResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'totalCount': json['totalCount'],
        'subscriptionPlans': ((json['subscriptionPlans'] as Array<any>).map(SubscriptionPlanResponseFromJSON)),
    };
}

export function SubscriptionPlanPageResponseToJSON(value?: SubscriptionPlanPageResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'totalCount': value['totalCount'],
        'subscriptionPlans': ((value['subscriptionPlans'] as Array<any>).map(SubscriptionPlanResponseToJSON)),
    };
}

