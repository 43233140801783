import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useEngagementsApi } from '@/api';
import {
  IEngagement,
  IGetEngagementsQueryParams,
  IGetEngagementsResponse,
  IMutateEngagementRequest,
} from '@/types';

import { useError } from './useError';

export const QUERY_KEY = (queryParams?: IGetEngagementsQueryParams, id?: string) => [
  'engagements',
  { ...queryParams },
  id,
];

export const useGetEngagements = (queryParams?: IGetEngagementsQueryParams, enabled = true) => {
  const { getEngagements } = useEngagementsApi();
  return useQuery<IGetEngagementsResponse, Error>({
    queryKey: QUERY_KEY(queryParams),
    queryFn: () => getEngagements(queryParams),
    enabled,
  });
};

export const useEngagementIdMap = (queryParams?: IGetEngagementsQueryParams) => {
  const { data: { engagements } = {} } = useGetEngagements({ limit: '0', ...queryParams });

  const engagementIdsMap = new Map<string, string>();

  engagements?.map(({ _id, label }: { _id: string; label: string }) =>
    engagementIdsMap.set(label, _id),
  );

  return engagementIdsMap;
};

const paginationLimit = '12';

export const useGetInfiniteEngagements = (queryParams?: IGetEngagementsQueryParams) => {
  const { getEngagements } = useEngagementsApi();
  return useInfiniteQuery<IGetEngagementsResponse, Error>({
    queryKey: QUERY_KEY(queryParams),
    queryFn: ({ pageParam = 0 }) =>
      getEngagements({ ...queryParams, limit: paginationLimit, page: pageParam as string }),

    getNextPageParam: (lastPage, allPages) =>
      lastPage.engagements.length === Number(paginationLimit) ? allPages.length + 1 : undefined,
    initialPageParam: 1,
  });
};

// export const useGetEngagementById = (id: string) => {
//   const { getEngagementById } = useEngagementsApi();
//   return useQuery<IEngagement, Error>(QUERY_KEY({}, id), () => getEngagementById(id), {
//     enabled: !!id,
//   });
// };

export const useCreateEngagement = () => {
  const queryClient = useQueryClient();
  const { createEngagement } = useEngagementsApi();
  const { reportError } = useError();
  return useMutation<IEngagement, Error, IMutateEngagementRequest, unknown>({
    mutationFn: (createEngagementRequest) => createEngagement(createEngagementRequest),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEY() });
    },
    onError: (error) => reportError(error),
  });
};
//
// export const useUpdateEngagement = () => {
//   const queryClient = useQueryClient();
//   const { updateEngagement } = useEngagementsApi();
//   return useMutation<IEngagement, Error, IMutateEngagementRequest, unknown>(
//     (createEngagementRequest) => updateEngagement(createEngagementRequest),
//     {
//       onSuccess: ({ _id }) => {
//         queryClient.invalidateQueries(QUERY_KEY());
//         queryClient.invalidateQueries(QUERY_KEY({}, _id));
//       },
//     },
//   );
// };
//
// export const useDeleteEngagement = () => {
//   const queryClient = useQueryClient();
//   const { deleteEngagement } = useEngagementsApi();
//   return useMutation<void, Error, { id: string }, unknown>(({ id }) => deleteEngagement(id), {
//     onSuccess: () => {
//       queryClient.invalidateQueries(QUERY_KEY());
//     },
//   });
// };
