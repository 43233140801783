export enum DisplayType {
  Card = 'CARD',
  Table = 'TABLE',
}

export enum PermissionAction {
  LIST = 'list',
  READ = 'read',
  CREATE = 'create',
  UPDATE = 'update',
  DESTROY = 'destroy',
  ASSIGN = 'assign',
  INVITE = 'invite',
}

export enum PermissionHandle {
  FILES = 'files',
  ORGS = 'organizations',
  USERS = 'users',
  USER_GROUPS = 'user_groups',
  VENDORS = 'vendors',
}

export const TOKEN_KEY = 'venddex-token';

export const ORG_ID_KEY = 'venddex-org-id';

export const PATHS = {
  acceptInvitation: '/activate',
  account: '/account',
  base: '/',
  checkout: '/checkout',
  contacts: '/contacts',
  documents: '/documents',
  engagements: '/engagements',
  forgotPassword: '/reset',
  login: '/login',
  notifications: '/notifications',
  onboarding: '/onboarding',
  payment: '/payment',
  pricing: '/pricing',
  prospects: '/prospects',
  resetPassword: '/reset',
  signUp: '/signup',
  support: '/support',
  upgrade: '/upgrade',
  users: '/users',
};
