import { IGetInvitationsResponse, IInvitation, IMutateInvitation } from '@/types';
import { setSearchParams } from '@/utils/set-search-params';

import { useHeaders } from './useHeaders';

const invitationsEndpoint = '/api/v1/invitations';

export const useInvitationsApi = () => {
  const headers = useHeaders();

  const createInvitation = async ({
    email,
    userGroup,
  }: IMutateInvitation): Promise<IInvitation> => {
    const response = await fetch(invitationsEndpoint, {
      method: 'POST',
      body: JSON.stringify({ email, userGroup }),
      headers,
    });

    const { error, message, invitation } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return invitation;
  };

  const getInvitations = async (queryParams?: any): Promise<IGetInvitationsResponse> => {
    const searchParams = new URLSearchParams();
    setSearchParams(queryParams, searchParams);

    const url =
      searchParams.size === 0
        ? invitationsEndpoint
        : `${invitationsEndpoint}?${searchParams.toString()}`;

    const response = await fetch(url, {
      headers,
    });

    const { error, invitations, message, totalResults } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return { totalResults, invitations };
  };

  const getInvitationById = async (id: string): Promise<IInvitation> => {
    const response = await fetch(`${invitationsEndpoint}/${id}`, {
      headers,
    });

    const { error, message, invitation } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return invitation;
  };

  const updateInvitation = async ({
    id,
    email,
    userGroup,
  }: IMutateInvitation): Promise<IInvitation> => {
    const response = await fetch(`${invitationsEndpoint}/${id}`, {
      method: 'PATCH',
      body: JSON.stringify({ email, userGroup }),
      headers,
    });

    const { error, message, invitation } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return invitation;
  };

  const deleteInvitation = async (id: string): Promise<IInvitation> => {
    const response = await fetch(`${invitationsEndpoint}/${id}`, {
      method: 'DELETE',
      headers,
    });

    const { error, message, invitation } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return invitation;
  };

  return {
    createInvitation,
    deleteInvitation,
    getInvitationById,
    getInvitations,
    updateInvitation,
  };
};
