import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export default function useContactSchema() {
  const { t } = useTranslation();

  return yup.object().shape({
    avatarUrl: yup.string(),
    email: yup.string().email(t('forms.common.emailValid')).optional(),
    engagements: yup.array(yup.string().required()).defined(),
    firstName: yup.string().required(t('forms.common.firstNameValidation')),
    lastName: yup.string().required(t('forms.common.lastNameValidation')),
    phoneNumbers: yup
      .array(
        yup.object().shape({
          number: yup
            .string()
            .when('$phoneNumbers', (phoneNumbers, schema) =>
              phoneNumbers && phoneNumbers.length > 0
                ? schema.required(t('forms.contacts.fields.phoneNumberValidation'))
                : schema,
            ),
          numberType: yup
            .string()
            .when('$phoneNumbers', (phoneNumbers, schema) =>
              phoneNumbers && phoneNumbers.length > 0
                ? schema.required(t('forms.contacts.fields.phoneNumberTypeValidation'))
                : schema,
            ),
        }),
      )
      .defined(),
    title: yup.string(),
    vendor: yup.string().required(t('forms.common.vendorValidation')),
  });
}
