import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { alpha } from '@mui/material/styles';

import { ISearchResult } from '@/types';

export default function Result({
  children,
  onClick,
  result,
}: {
  children: React.ReactNode;
  onClick: VoidFunction;
  result: ISearchResult;
}) {
  const { firstName, label, lastName, matches, title } = result;
  // vendor?

  return (
    <ListItemButton
      onClick={onClick}
      sx={{
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: 'transparent',
        borderBottomColor: (theme) => theme.palette.divider,
        '&:hover': {
          borderRadius: 1,
          borderColor: (theme) => theme.palette.primary.main,
          backgroundColor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
        },
      }}
    >
      <ListItemText
        primaryTypographyProps={{
          typography: 'subtitle2',
        }}
        secondaryTypographyProps={{ typography: 'caption' }}
        primary={
          <Box
            component="span"
            sx={{
              color: 'text.primary',
            }}
          >
            {label || `${firstName} ${lastName} - ${title}`}
          </Box>
        }
        secondary={Object.entries(matches).map(([_key, match], index) => {
          if (Array.isArray(match)) {
            return (
              <span key={index}>
                {match.map((item, idx) => (
                  <Box
                    key={idx}
                    dangerouslySetInnerHTML={{ __html: item.value }}
                    component="span"
                    sx={{
                      color: 'text.secondary',
                      display: 'block',
                      fontStyle: 'normal',
                      '& em': {
                        color: 'primary.main',
                        fontStyle: 'normal',
                      },
                    }}
                  />
                ))}
              </span>
            );
          }
          return (
            <Box
              key={index}
              component="span"
              dangerouslySetInnerHTML={{ __html: match.value }}
              sx={{
                color: 'text.secondary',
                display: 'block',
                fontStyle: 'normal',
                '& em': {
                  color: 'primary.main',
                  fontStyle: 'normal',
                },
              }}
            />
          );
        })}
      />

      {children}
    </ListItemButton>
  );
}
