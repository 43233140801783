import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useOrganizationsApi } from '@/api/useUsers';
import { type CreateOrganizationRequest, OrganizationResponse } from '@/client';
import { IStatusOption, StatusOptionTypeEnum } from '@/types';

import { useError } from './useError';
import { QUERY_KEY as SESSION_QUERY_KEY } from './useSession';

export const QUERY_KEY = (id?: string) => ['organizations', id];

export const useGetOrganization = (id?: string) => {
  const organizationsApi = useOrganizationsApi();
  return useQuery<OrganizationResponse, Error>({
    queryKey: QUERY_KEY(id),
    queryFn: () => organizationsApi.getOrganizationById({ id: id as string }),
    enabled: !!id,
  });
};

export const useCreateOrganization = () => {
  const queryClient = useQueryClient();
  const organizationsApi = useOrganizationsApi();
  const { reportError } = useError();
  return useMutation<OrganizationResponse, Error, CreateOrganizationRequest>({
    mutationFn: (createOrganizationRequest) =>
      organizationsApi.createOrganization({ createOrganizationRequest }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEY() });
      queryClient.invalidateQueries({ queryKey: SESSION_QUERY_KEY() });
    },
    onError: (error) => reportError(error),
  });
};

export const useStatusOptions = (statusOptions: IStatusOption[], type: StatusOptionTypeEnum) =>
  statusOptions.filter(({ statusType }) => statusType === type);
