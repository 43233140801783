/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CreateFeatureRequest } from './CreateFeatureRequest';
import {
    CreateFeatureRequestFromJSON,
    CreateFeatureRequestFromJSONTyped,
    CreateFeatureRequestToJSON,
} from './CreateFeatureRequest';

/**
 * 
 * @export
 * @interface CreateSubscriptionPlanRequest
 */
export interface CreateSubscriptionPlanRequest {
    /**
     * 
     * @type {Array<CreateFeatureRequest>}
     * @memberof CreateSubscriptionPlanRequest
     */
    features?: Array<CreateFeatureRequest>;
    /**
     * 
     * @type {string}
     * @memberof CreateSubscriptionPlanRequest
     */
    label: string;
    /**
     * 
     * @type {number}
     * @memberof CreateSubscriptionPlanRequest
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof CreateSubscriptionPlanRequest
     */
    status?: CreateSubscriptionPlanRequestStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSubscriptionPlanRequest
     */
    isDefault?: boolean;
}


/**
 * @export
 */
export const CreateSubscriptionPlanRequestStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    Deprecated: 'DEPRECATED'
} as const;
export type CreateSubscriptionPlanRequestStatusEnum = typeof CreateSubscriptionPlanRequestStatusEnum[keyof typeof CreateSubscriptionPlanRequestStatusEnum];


/**
 * Check if a given object implements the CreateSubscriptionPlanRequest interface.
 */
export function instanceOfCreateSubscriptionPlanRequest(value: object): value is CreateSubscriptionPlanRequest {
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('price' in value) || value['price'] === undefined) return false;
    return true;
}

export function CreateSubscriptionPlanRequestFromJSON(json: any): CreateSubscriptionPlanRequest {
    return CreateSubscriptionPlanRequestFromJSONTyped(json, false);
}

export function CreateSubscriptionPlanRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSubscriptionPlanRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'features': json['features'] == null ? undefined : ((json['features'] as Array<any>).map(CreateFeatureRequestFromJSON)),
        'label': json['label'],
        'price': json['price'],
        'status': json['status'] == null ? undefined : json['status'],
        'isDefault': json['isDefault'] == null ? undefined : json['isDefault'],
    };
}

export function CreateSubscriptionPlanRequestToJSON(value?: CreateSubscriptionPlanRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'features': value['features'] == null ? undefined : ((value['features'] as Array<any>).map(CreateFeatureRequestToJSON)),
        'label': value['label'],
        'price': value['price'],
        'status': value['status'],
        'isDefault': value['isDefault'],
    };
}

