/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FeatureResponse
 */
export interface FeatureResponse {
    /**
     * 
     * @type {string}
     * @memberof FeatureResponse
     */
    handle: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureResponse
     */
    label: string;
    /**
     * 
     * @type {number}
     * @memberof FeatureResponse
     */
    limit: number;
}

/**
 * Check if a given object implements the FeatureResponse interface.
 */
export function instanceOfFeatureResponse(value: object): value is FeatureResponse {
    if (!('handle' in value) || value['handle'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('limit' in value) || value['limit'] === undefined) return false;
    return true;
}

export function FeatureResponseFromJSON(json: any): FeatureResponse {
    return FeatureResponseFromJSONTyped(json, false);
}

export function FeatureResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeatureResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'handle': json['handle'],
        'label': json['label'],
        'limit': json['limit'],
    };
}

export function FeatureResponseToJSON(value?: FeatureResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'handle': value['handle'],
        'label': value['label'],
        'limit': value['limit'],
    };
}

