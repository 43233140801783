import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';

import { useVendorsApi } from '@/api';
import { useVendorsApi as useNew } from '@/api/useUsers';
import { VendorPageResponse } from '@/client';
import { useAppContext } from '@/providers';
import {
  ICreateVendorRequest,
  IGetVendorsQueryParams,
  IGetVendorsResponse,
  IUpdateVendorRequest,
  IVendor,
} from '@/types';

import { useError } from './useError';
import { QUERY_KEY as TAGS_QUERY_KEY } from './useTags';

export const QUERY_KEY = (
  orgId: string,
  queryParams?: IGetVendorsQueryParams,
  vendorId?: string,
) => ['vendors', { ...queryParams }, orgId, vendorId];

export const useGetVendors = (
  queryParams?: IGetVendorsQueryParams,
  queryOptions?: UseQueryOptions<IGetVendorsResponse, Error>,
) => {
  const { getVendors } = useVendorsApi();
  const { currentOrganization } = useAppContext();
  return useQuery<IGetVendorsResponse, Error>({
    queryKey: QUERY_KEY(currentOrganization.id, queryParams),
    queryFn: () => getVendors(queryParams),
    enabled: !!currentOrganization?.id,
    ...queryOptions,
  });
};

const paginationLimit = 15;

export const useGetInfiniteVendors = (queryParams?: IGetVendorsQueryParams) => {
  const vendorsApi = useNew();
  const { currentOrganization } = useAppContext();
  return useInfiniteQuery<VendorPageResponse, Error>({
    queryKey: QUERY_KEY(currentOrganization.id, queryParams),
    queryFn: ({ pageParam }) =>
      vendorsApi.getVendors({
        size: paginationLimit,
        page: Number(pageParam),
        xOrganizationId: currentOrganization.id,
      }),
    enabled: !!currentOrganization.id,
    getNextPageParam: (lastPage, allPages) =>
      lastPage.vendors.length === Number(paginationLimit) ? allPages.length + 1 : undefined,
    initialPageParam: 1,
  });
};

export const useGetVendorById = (id: string) => {
  const { getVendorById } = useVendorsApi();
  const { currentOrganization } = useAppContext();
  return useQuery<IVendor, Error>({
    queryKey: QUERY_KEY(currentOrganization.id, {}, id),
    queryFn: () => getVendorById(id),
  });
};

export const useCreateVendor = () => {
  const queryClient = useQueryClient();
  const { createVendor } = useVendorsApi();
  const { reportError } = useError();
  return useMutation<IVendor, Error, ICreateVendorRequest, unknown>({
    mutationFn: (createVendorRequest) => createVendor(createVendorRequest),
    onSuccess: ({ organization }) => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEY(organization) });
      queryClient.invalidateQueries({ queryKey: TAGS_QUERY_KEY(organization) });
    },
    onError: (error) => reportError(error),
  });
};

export const useUpdateVendor = () => {
  const queryClient = useQueryClient();
  const { updateVendor } = useVendorsApi();
  const { reportError } = useError();
  return useMutation<IVendor, Error, IUpdateVendorRequest, unknown>({
    mutationFn: (updateVendorRequest) => updateVendor(updateVendorRequest),
    onSuccess: ({ _id, organization }) => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEY(organization, {}, _id) });
      queryClient.invalidateQueries({ queryKey: TAGS_QUERY_KEY(organization) });
    },
    onError: (error) => reportError(error),
  });
};

export const useDeleteVendor = () => {
  const queryClient = useQueryClient();
  const { deleteVendor } = useVendorsApi();
  const { reportError } = useError();
  return useMutation<IVendor, Error, { id: string }, unknown>({
    mutationFn: ({ id }) => deleteVendor(id),
    onSuccess: ({ organization }) => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEY(organization) });
    },
    onError: (error) => reportError(error),
  });
};
