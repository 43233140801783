import * as Yup from 'yup';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';

import { countries } from '@/assets/data';
import { RHFAutocomplete, RHFTextField } from '@/components/hook-form';

export const NewAddressSchema = Yup.object().shape({
  address: Yup.string().required('Address is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  zipCode: Yup.string().required('Zip code is required'),
  country: Yup.string().required('Country is required'),
  // not required
  // addressType: Yup.string(),
  // primary: Yup.boolean(),
});

export default function AddressFields() {
  return (
    <Grid xs={12} md={6}>
      <Stack spacing={3}>
        <RHFTextField name="address" label="Address" />
        <Box
          rowGap={3}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
          }}
        >
          <RHFAutocomplete
            name="country"
            type="country"
            label="Country"
            placeholder="Choose a country"
            options={countries.map((option) => option.label)}
            getOptionLabel={(option) => option}
          />

          <RHFTextField name="state" label="State/Region" />
          <RHFTextField name="city" label="City" />
          <RHFTextField name="zipCode" label="Zip/Code" />
        </Box>
      </Stack>
    </Grid>
  );
}
