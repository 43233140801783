import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import FormProvider, { RHFTextField } from '@/components/hook-form';
import Iconify from '@/components/iconify';
import { useBoolean } from '@/hooks/use-boolean';
import { useUpdateUser } from '@/hooks/useUsers';
import { useAppContext } from '@/providers';

const SubmitButton = ({
  isFullWidth,
  isLoading,
}: {
  isFullWidth?: boolean;
  isLoading: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <LoadingButton
      fullWidth={isFullWidth}
      color="inherit"
      size="large"
      type="submit"
      variant="contained"
      loading={isLoading}
      endIcon={<Iconify icon="eva:arrow-ios-forward-fill" />}
      sx={{ justifyContent: 'space-between', pl: 2, pr: 1.5 }}
    >
      {t('forms.updatePassword.submit')}
    </LoadingButton>
  );
};

export default function UpdatePasswordForm({ fullWidth }: { fullWidth?: boolean }) {
  const { t } = useTranslation();

  const formMethods = useForm({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(
      yup
        .object({
          password: yup
            .string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters'),
          confirmPassword: yup
            .string()
            .oneOf([yup.ref('password')], 'Passwords must match')
            .required('Confirm Password is required'),
        })
        .required(),
    ),
  });

  const password = useBoolean();

  const confirmPassword = useBoolean();

  const { currentUser } = useAppContext();

  const { isPending, mutate: updateUser } = useUpdateUser();

  const navigate = useNavigate();

  const { handleSubmit } = formMethods;

  const onSubmit = handleSubmit((values) => {
    updateUser(
      { _id: currentUser.id, ...values },
      {
        onSuccess: () => navigate('/'),
      },
    );
  });

  return (
    <FormProvider methods={formMethods} onSubmit={onSubmit}>
      <Stack spacing={2.5}>
        <Typography variant={fullWidth ? 'h4' : 'h6'}>
          {t('pages.updatePassword.heading')}
        </Typography>

        <RHFTextField
          name="password"
          label={t('forms.common.password')}
          type={password.value ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={password.onToggle} edge="end">
                  <Iconify icon={password.value ? 'mdi:eye-outline' : 'mdi:eye-off-outline'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <RHFTextField
          name="confirmPassword"
          label={t('forms.updatePassword.confirmPassword')}
          type={confirmPassword.value ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={confirmPassword.onToggle} edge="end">
                  <Iconify
                    icon={confirmPassword.value ? 'mdi:eye-outline' : 'mdi:eye-off-outline'}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        {fullWidth ? (
          <SubmitButton isFullWidth isLoading={isPending} />
        ) : (
          <Stack direction="row" justifyContent="flex-end">
            <SubmitButton isLoading={isPending} />
          </Stack>
        )}
      </Stack>
    </FormProvider>
  );
}
