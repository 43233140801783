import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import posthog from 'posthog-js';

import { useInvitationsApi, useUserInvitationsApi } from '@/api';
import { useAppContext } from '@/providers';
import {
  IGetInvitationsResponse,
  IInvitation,
  IMutateInvitation,
  UpdateInvitationResponse,
} from '@/types';

import { useError } from './useError';

const QUERY_KEY = (queryParams?: any, id?: string) => ['invitations', id, { ...queryParams }];

export const useCreateInvitation = () => {
  const { createInvitation } = useInvitationsApi();
  const queryClient = useQueryClient();
  const { reportError } = useError();
  return useMutation<IInvitation, Error, IMutateInvitation>({
    mutationFn: ({ email, userGroup }) =>
      createInvitation({
        email,
        userGroup,
      }),
    onSuccess: ({ recipient }) => {
      posthog.capture('invitation_sent', { recipient });
      queryClient.invalidateQueries({ queryKey: QUERY_KEY() });
    },
    onError: (error) => reportError(error),
  });
};

export const useGetUserInvitationById = (id: string) => {
  const { getInvitationById } = useUserInvitationsApi();
  return useQuery<IInvitation, Error>({
    queryKey: QUERY_KEY(id),
    queryFn: () => getInvitationById(id),
  });
};

const paginationLimit = '15';

export const useGetInfiniteInvitations = (queryParams?: any) => {
  const { getInvitations } = useInvitationsApi();
  const { currentOrganization } = useAppContext();
  return useInfiniteQuery<IGetInvitationsResponse, Error>({
    queryKey: QUERY_KEY(queryParams),
    queryFn: ({ pageParam }) =>
      getInvitations({ ...queryParams, limit: paginationLimit, page: pageParam as string }),
    enabled: !!currentOrganization.id,
    getNextPageParam: (lastPage, allPages) =>
      lastPage.invitations.length === Number(paginationLimit) ? allPages.length + 1 : undefined,
    initialPageParam: 1,
  });
};

export const useGetInvitationById = (id: string) => {
  const { getInvitationById } = useInvitationsApi();
  return useQuery<IInvitation, Error>({
    queryKey: QUERY_KEY({}, id),
    queryFn: () => getInvitationById(id),
  });
};

export const useUpdateInvitation = () => {
  const { updateInvitation } = useInvitationsApi();
  const queryClient = useQueryClient();
  const { reportError } = useError();
  return useMutation<IInvitation, Error, IMutateInvitation>({
    mutationFn: (updateInvitationRequest) => updateInvitation(updateInvitationRequest),
    onSuccess: ({ _id }) => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEY({}) });
      queryClient.invalidateQueries({ queryKey: QUERY_KEY({}, _id) });
    },
    onError: (error) => reportError(error),
  });
};

export const useUpdateUserInvitation = () => {
  const { updateInvitation } = useUserInvitationsApi();
  return useMutation<UpdateInvitationResponse, Error, { invitationId: string }, unknown>({
    mutationFn: ({ invitationId }) => updateInvitation(invitationId),
  });
};

export const useDeleteInvitation = () => {
  const { deleteInvitation } = useInvitationsApi();
  const queryClient = useQueryClient();
  const { reportError } = useError();
  return useMutation<IInvitation, Error, { id: string }, unknown>({
    mutationFn: ({ id }) => deleteInvitation(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEY({}) });
    },
    onError: (error) => reportError(error),
  });
};
