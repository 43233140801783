import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormHelperText from '@mui/material/FormHelperText';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';

import { RHFTextField } from '@/components/hook-form';
import { ICreateReviewRequest } from '@/types';

export default function ReviewFields() {
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
  } = useFormContext<ICreateReviewRequest>();

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={3} alignItems="flex-end">
        <Controller
          name="rating"
          control={control}
          render={({ field }) => (
            <Rating
              {...field}
              size="small"
              value={Number(field.value)}
              onChange={(event, newValue) => {
                field.onChange(newValue as number);
              }}
            />
          )}
        />
        {!!errors.rating && <FormHelperText error> {errors.rating?.message}</FormHelperText>}
      </Stack>

      <RHFTextField name="comment" label={t('forms.reviews.fields.comment')} multiline rows={3} />
    </Stack>
  );
}
