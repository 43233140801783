import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import FormProvider, { RHFTextField } from '@/components/hook-form';
import Iconify from '@/components/iconify';
import { PATHS } from '@/constants';
import { useBoolean } from '@/hooks/use-boolean';
import { useCreateUser } from '@/hooks/useUsers';
import { useAppContext } from '@/providers';
import { RouterLink } from '@/routes/components';

export default function SignUpForm({
  handleSignInClick,
  onSuccess,
}: {
  handleSignInClick?: VoidFunction;
  onSuccess?: VoidFunction;
}) {
  const password = useBoolean();

  const { t } = useTranslation();

  const signUpSchema = yup.object().shape({
    email: yup
      .string()
      .required(t('forms.common.emailRequired'))
      .email(t('forms.common.emailValid')),
    firstName: yup.string().required(t('forms.common.firstNameValidation')),
    lastName: yup.string().required(t('forms.common.lastNameValidation')),
    password: yup.string().required(t('forms.common.passwordValidation')),
  });

  const defaultValues = {
    email: '',
    firstName: '',
    lastName: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(signUpSchema),
    defaultValues,
  });

  const { handleSubmit } = methods;

  const { isPending, mutate: createUser } = useCreateUser();

  const { setCurrentUser } = useAppContext();

  const onSubmit = handleSubmit((values) => {
    createUser(values, {
      onSuccess: (user) => {
        setCurrentUser(user);
        if (onSuccess) {
          onSuccess();
        }
      },
    });
  });

  return (
    <>
      <Stack spacing={2} sx={{ mb: 5 }}>
        <Typography variant="h4">{t('pages.signup.heading')}</Typography>

        <Stack direction="row" spacing={0.5}>
          <Typography variant="body2">{t('pages.signup.subHeading')}</Typography>

          {handleSignInClick ? (
            <Link component="button" onClick={handleSignInClick} variant="subtitle2">
              {t('buttons.signIn')}
            </Link>
          ) : (
            <Link component={RouterLink} href={PATHS.login} variant="subtitle2">
              {t('buttons.signIn')}
            </Link>
          )}
        </Stack>
      </Stack>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Stack spacing={2} sx={{ mb: 5 }}>
          <RHFTextField name="firstName" label={t('forms.common.firstName')} />
          <RHFTextField name="lastName" label={t('forms.common.lastName')} />
          <RHFTextField name="email" label={t('forms.common.email')} />
          <RHFTextField
            name="password"
            label={t('forms.common.password')}
            type={password.value ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={password.onToggle} edge="end">
                    <Iconify icon={password.value ? 'mdi:eye-outline' : 'mdi:eye-off-outline'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <LoadingButton
            fullWidth
            color="inherit"
            size="large"
            type="submit"
            variant="contained"
            loading={isPending}
            endIcon={<Iconify icon="eva:arrow-ios-forward-fill" />}
            sx={{ justifyContent: 'space-between', pl: 2, pr: 1.5 }}
          >
            {t('buttons.signUp')}
          </LoadingButton>
        </Stack>
      </FormProvider>
    </>
  );
}
