import { useHeaders } from './useHeaders';

export const useSearchApi = () => {
  const headers = useHeaders();

  const search = async ({ page, searchTerm }: { page: string; searchTerm: string }) => {
    const searchParams = new URLSearchParams();
    searchParams.append('page', page);
    const response = await fetch(`/api/v1/search/?${searchParams.toString()}`, {
      body: JSON.stringify({ searchTerm }),
      headers,
      method: 'POST',
    });

    const { error, message, result } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return result;
  };

  return { search };
};
