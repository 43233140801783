/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { VendorResponse } from './VendorResponse';
import {
    VendorResponseFromJSON,
    VendorResponseFromJSONTyped,
    VendorResponseToJSON,
} from './VendorResponse';

/**
 * 
 * @export
 * @interface VendorPageResponse
 */
export interface VendorPageResponse {
    /**
     * 
     * @type {number}
     * @memberof VendorPageResponse
     */
    totalCount: number;
    /**
     * 
     * @type {Array<VendorResponse>}
     * @memberof VendorPageResponse
     */
    vendors: Array<VendorResponse>;
}

/**
 * Check if a given object implements the VendorPageResponse interface.
 */
export function instanceOfVendorPageResponse(value: object): value is VendorPageResponse {
    if (!('totalCount' in value) || value['totalCount'] === undefined) return false;
    if (!('vendors' in value) || value['vendors'] === undefined) return false;
    return true;
}

export function VendorPageResponseFromJSON(json: any): VendorPageResponse {
    return VendorPageResponseFromJSONTyped(json, false);
}

export function VendorPageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VendorPageResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'totalCount': json['totalCount'],
        'vendors': ((json['vendors'] as Array<any>).map(VendorResponseFromJSON)),
    };
}

export function VendorPageResponseToJSON(value?: VendorPageResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'totalCount': value['totalCount'],
        'vendors': ((value['vendors'] as Array<any>).map(VendorResponseToJSON)),
    };
}

