import {
  IContactResponse,
  ICreateContactRequest,
  IGetContactsQueryParams,
  IGetContactsResponse,
  IUpdateContactRequest,
} from '@/types';
import { setSearchParams } from '@/utils/set-search-params';

import { useHeaders } from './useHeaders';

const contactsEndpoint = '/api/v1/contacts';

export const useContactsApi = () => {
  const headers = useHeaders();

  const getContacts = async (
    queryParams?: IGetContactsQueryParams,
  ): Promise<IGetContactsResponse> => {
    const searchParams = new URLSearchParams();
    setSearchParams(queryParams, searchParams);

    const url =
      searchParams.size === 0 ? contactsEndpoint : `${contactsEndpoint}?${searchParams.toString()}`;

    const response = await fetch(url, {
      headers,
    });

    const { contacts, error, message, totalResults } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return { contacts, totalResults };
  };

  const getContactById = async (id: string): Promise<IContactResponse> => {
    const response = await fetch(`${contactsEndpoint}/${id}`, {
      headers,
    });

    const { contact, error, message } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return contact;
  };

  const createContact = async (
    createContactRequest: ICreateContactRequest,
  ): Promise<IContactResponse> => {
    const response = await fetch(contactsEndpoint, {
      method: 'POST',
      body: JSON.stringify(createContactRequest),
      headers,
    });

    const { contact, error, message } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return contact;
  };

  const deleteContact = async (id: string): Promise<void> => {
    const response = await fetch(`${contactsEndpoint}/${id}`, {
      method: 'DELETE',
      headers,
    });

    const { error, message } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }
  };

  const updateContact = async (
    updateContactRequest: IUpdateContactRequest,
  ): Promise<IContactResponse> => {
    const response = await fetch(`${contactsEndpoint}/${updateContactRequest._id}`, {
      method: 'PATCH',
      body: JSON.stringify(updateContactRequest),
      headers,
    });

    const { contact, error, message } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return contact;
  };

  return {
    createContact,
    deleteContact,
    getContactById,
    getContacts,
    updateContact,
  };
};
