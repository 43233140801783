import posthog from 'posthog-js';

import { useSnackbar } from '@/components/snackbar';

// TODO: REPLACE COMPONENT LEVEL USAGE

export const useError = () => {
  const { enqueueSnackbar } = useSnackbar();
  return {
    reportError: (error: Error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
      posthog.capture('error', { message: String(error) });
    },
  };
};
