/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentMethodResponse
 */
export interface PaymentMethodResponse {
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodResponse
     */
    brand: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentMethodResponse
     */
    expMonth: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentMethodResponse
     */
    expYear: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodResponse
     */
    last4: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentMethodResponse
     */
    _default?: boolean;
}

/**
 * Check if a given object implements the PaymentMethodResponse interface.
 */
export function instanceOfPaymentMethodResponse(value: object): value is PaymentMethodResponse {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('brand' in value) || value['brand'] === undefined) return false;
    if (!('expMonth' in value) || value['expMonth'] === undefined) return false;
    if (!('expYear' in value) || value['expYear'] === undefined) return false;
    if (!('last4' in value) || value['last4'] === undefined) return false;
    return true;
}

export function PaymentMethodResponseFromJSON(json: any): PaymentMethodResponse {
    return PaymentMethodResponseFromJSONTyped(json, false);
}

export function PaymentMethodResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentMethodResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'brand': json['brand'],
        'expMonth': json['expMonth'],
        'expYear': json['expYear'],
        'last4': json['last4'],
        '_default': json['default'] == null ? undefined : json['default'],
    };
}

export function PaymentMethodResponseToJSON(value?: PaymentMethodResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'brand': value['brand'],
        'expMonth': value['expMonth'],
        'expYear': value['expYear'],
        'last4': value['last4'],
        'default': value['_default'],
    };
}

