import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import FormProvider, { RHFTextField } from '@/components/hook-form';
import Iconify from '@/components/iconify';
import { PATHS } from '@/constants';
import { useBoolean } from '@/hooks/use-boolean';
import { useLogin } from '@/hooks/useSession';
import { useAppContext } from '@/providers';
import { RouterLink } from '@/routes/components';

// ----------------------------------------------------------------------

export default function LoginForm({
  handleSignUpClick,
  onSuccess,
}: {
  handleSignUpClick?: VoidFunction;
  onSuccess?: VoidFunction;
}) {
  const password = useBoolean();

  const { t } = useTranslation();

  // TODO: CLEANUP NAME< MODULARIZE EMAIL SCHEMA
  const LoginSchema = yup.object().shape({
    email: yup
      .string()
      .required(t('forms.common.emailRequired'))
      .email(t('forms.common.emailValid')),
    password: yup.string().required(t('forms.common.passwordValidation')),
  });

  const defaultValues = {
    email: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const { isPending, mutate: login } = useLogin();

  const { handleSubmit } = methods;

  const { setCurrentOrganization, setCurrentUser } = useAppContext();

  const onSubmit = handleSubmit((values) => {
    login(values, {
      onSuccess: (user) => {
        // TODO: CHECK LOCAL STORAGE FOR ORG BEFORE DEFAULT TO [0]
        setCurrentUser(user);
        setCurrentOrganization(user.organizations[0] || {});
        if (onSuccess) {
          onSuccess();
        }
      },
    });
  });

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5 }}>
      <Typography variant="h4">{t('pages.login.heading')}</Typography>

      <Stack direction="row" spacing={0.5}>
        <Typography variant="body2">{t('pages.login.subHeading')}</Typography>

        {handleSignUpClick ? (
          <Link component="button" onClick={handleSignUpClick} variant="subtitle2">
            {t('buttons.signUp')}
          </Link>
        ) : (
          <Link component={RouterLink} href={PATHS.signUp} variant="subtitle2">
            {t('buttons.signUp')}
          </Link>
        )}
      </Stack>
    </Stack>
  );

  const renderForm = (
    <Stack spacing={2.5}>
      <RHFTextField name="email" label={t('forms.common.email')} />

      <RHFTextField
        name="password"
        label={t('forms.common.password')}
        type={password.value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={password.onToggle} edge="end">
                <Iconify icon={password.value ? 'mdi:eye-outline' : 'mdi:eye-off-outline'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Link
        component={RouterLink}
        href={PATHS.resetPassword}
        variant="body2"
        color="inherit"
        underline="always"
        sx={{ alignSelf: 'flex-end' }}
      >
        {t('buttons.resetPassword')}
      </Link>

      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isPending}
        endIcon={<Iconify icon="eva:arrow-ios-forward-fill" />}
        sx={{ justifyContent: 'space-between', pl: 2, pr: 1.5 }}
      >
        {t('buttons.signIn')}
      </LoadingButton>
    </Stack>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {renderHead}

      {renderForm}
    </FormProvider>
  );
}
