import Cookies from 'js-cookie';

import { TOKEN_KEY } from '@/constants';
import {
  ICreateUserRequest,
  ICreateUserResponse,
  IGetUsersQueryParams,
  IGetUsersResponse,
  IUpdateUserRequest,
  IUser,
} from '@/types';
import { setSearchParams } from '@/utils/set-search-params';

import { useHeaders } from './useHeaders';

const usersEndpoint = `/api/v1/users`;

export const createUser = async (
  userCredentials: ICreateUserRequest,
): Promise<ICreateUserResponse> => {
  const response = await fetch(usersEndpoint, {
    method: 'POST',
    body: JSON.stringify(userCredentials),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const { errors, currentSession, user } = await response.json();

  if (errors) {
    const { msg } = errors[0];
    throw new Error(`${response.status}: ${msg}`);
  }

  const token = response.headers.get('Token');
  Cookies.set(TOKEN_KEY, token as string);

  return { currentSession, user };
};

export const useUsersApi = () => {
  const headers = useHeaders();

  const getUsers = async (queryParams?: IGetUsersQueryParams): Promise<IGetUsersResponse> => {
    const searchParams = new URLSearchParams();
    setSearchParams(queryParams, searchParams);
    const response = await fetch(`${usersEndpoint}?${searchParams.toString()}`, {
      headers,
    });

    const { error, message, totalResults, users } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return { totalResults, users };
  };

  const getUserById = async (id: string): Promise<IUser> => {
    const response = await fetch(`${usersEndpoint}/${id}`, {
      headers,
    });

    const { errors, user } = await response.json();

    if (errors) {
      const { msg } = errors[0];
      throw new Error(`${response.status}: ${msg}`);
    }

    return user;
  };

  const updateUser = async (updatedUser: IUpdateUserRequest): Promise<IUser> => {
    const { _id, email, firstName, lastName, password } = updatedUser;
    const response = await fetch(`${usersEndpoint}/${_id}`, {
      method: 'PATCH',
      body: JSON.stringify({ email, firstName, lastName, password }),
      headers,
    });

    const { errors, user } = await response.json();

    if (errors) {
      const { msg } = errors[0];
      throw new Error(`${response.status}: ${msg}`);
    }

    return user;
  };

  const deleteUser = async (id: string): Promise<IUser> => {
    const response = await fetch(`${usersEndpoint}/${id}`, {
      method: 'DELETE',
      headers,
    });

    const { errors, user } = await response.json();

    if (errors) {
      const { msg } = errors[0];
      throw new Error(`${response.status}: ${msg}`);
    }

    return user;
  };

  return { getUsers, getUserById, updateUser, deleteUser };
};
