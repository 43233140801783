import { useAppContext } from '@/providers';
import { IInvitation, UpdateInvitationResponse } from '@/types';

import { useHeaders } from './useHeaders';

export const useUserInvitationsApi = () => {
  const headers = useHeaders();
  const { currentUser } = useAppContext();
  const getInvitationById = async (invitationId: string): Promise<IInvitation> => {
    const response = await fetch(`/api/v1/users/${currentUser.id}/invitations/${invitationId}`, {
      headers,
    });

    const { error, message, invitation } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return invitation;
  };

  const updateInvitation = async (invitationId: string): Promise<UpdateInvitationResponse> => {
    const response = await fetch(`/api/v1/users/${currentUser.id}/invitations/${invitationId}`, {
      method: 'PATCH',
      headers,
    });

    const { error, invitation, message, user } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return { invitation, user };
  };

  return {
    getInvitationById,
    updateInvitation,
  };
};
