import {
  useInfiniteQuery,
  // useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { useContactsApi } from '@/api';
import {
  IContactResponse,
  ICreateContactRequest,
  IGetContactsQueryParams,
  IGetContactsResponse,
  IUpdateContactRequest,
} from '@/types';

import { useError } from './useError';

const QUERY_KEY = (queryParams?: IGetContactsQueryParams, id?: string) => [
  'contacts',
  { ...queryParams },
  id,
];

export const useGetContacts = (queryParams?: IGetContactsQueryParams) => {
  const { getContacts } = useContactsApi();
  return useQuery<IGetContactsResponse, Error>({
    queryKey: QUERY_KEY(queryParams),
    queryFn: () => getContacts(queryParams),
  });
};

const paginationLimit = '15';

export const useGetInfiniteContacts = (queryParams?: IGetContactsQueryParams) => {
  const { getContacts } = useContactsApi();
  return useInfiniteQuery<IGetContactsResponse, Error>({
    queryKey: QUERY_KEY(queryParams),
    queryFn: ({ pageParam = 0 }) =>
      getContacts({ ...queryParams, limit: paginationLimit, page: pageParam as string }),
    getNextPageParam: (lastPage, allPages) =>
      lastPage.contacts.length === Number(paginationLimit) ? allPages.length + 1 : undefined,
    initialPageParam: 1,
  });
};

export const useGetContactById = (id: string) => {
  const { getContactById } = useContactsApi();
  return useQuery<IContactResponse, Error>({
    queryKey: QUERY_KEY({}, id),
    queryFn: () => getContactById(id),
    enabled: !!id,
  });
};

export const useCreateContact = () => {
  const queryClient = useQueryClient();
  const { createContact } = useContactsApi();
  const { reportError } = useError();
  return useMutation<IContactResponse, Error, ICreateContactRequest, unknown>({
    mutationFn: (contact) => createContact(contact),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEY() });
    },
    onError: (error) => reportError(error),
  });
};

export const useUpdateContact = () => {
  const queryClient = useQueryClient();
  const { updateContact } = useContactsApi();
  const { reportError } = useError();
  return useMutation<IContactResponse, Error, IUpdateContactRequest, unknown>({
    mutationFn: (contact) => updateContact(contact),
    onSuccess: ({ _id }) => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEY() });
      queryClient.invalidateQueries({ queryKey: QUERY_KEY({}, _id) });
    },
    onError: (error) => reportError(error),
  });
};

export const useDeleteContact = () => {
  const queryClient = useQueryClient();
  const { deleteContact } = useContactsApi();
  const { reportError } = useError();
  return useMutation<void, Error, { id: string }, unknown>({
    mutationFn: ({ id }) => deleteContact(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEY() });
    },
    onError: (error) => reportError(error),
  });
};
