import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Alert } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';

import PhoneNumbers from '@/components/forms/PhoneNumbers';
import { RHFTextField, RHFUploadAvatar } from '@/components/hook-form';
import { LoadingScreen } from '@/components/loading-screen';
import { useGetEngagements } from '@/hooks/useEngagements';
import { fData } from '@/utils/format-number';

import EngagementsSelect from './EngagementsSelect';
import VendorSelect from './VendorSelect';

export default function ContactFields({ isVendorDisabled }: { isVendorDisabled?: boolean }) {
  const { setValue, watch } = useFormContext();

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      // const newFile = Object.assign(file, {
      //   preview: URL.createObjectURL(file),
      // });

      if (file) {
        setValue('avatarUrl', '', { shouldValidate: true });
      }
    },
    [setValue],
  );

  const { t } = useTranslation();

  const watchVendor = watch('vendor');

  const {
    data: { engagements } = {},
    error,
    isPending: isIdle,
    isLoading,
    isSuccess,
  } = useGetEngagements({ limit: '0', vendor: watchVendor }, !!watchVendor);

  return (
    <Grid container spacing={3}>
      <Grid xs={12} md={4}>
        <Card sx={{ pt: 10, pb: 5, px: 3 }}>
          <Box sx={{ mb: 5 }}>
            <RHFUploadAvatar
              name="avatarUrl"
              maxSize={3145728}
              onDrop={handleDrop}
              helperText={
                <Typography
                  variant="caption"
                  sx={{
                    mt: 3,
                    mx: 'auto',
                    display: 'block',
                    textAlign: 'center',
                    color: 'text.disabled',
                  }}
                >
                  Allowed *.jpeg, *.jpg, *.png, *.gif
                  <br /> max size of {fData(3145728)}
                </Typography>
              }
            />
          </Box>
        </Card>
      </Grid>
      <Grid xs={12} md={8}>
        <Card sx={{ p: 3, minHeight: '364px' }}>
          <Stack spacing={2}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <VendorSelect isDisabled={isVendorDisabled} />
              {isLoading && <LoadingScreen />}
              {error && <Alert severity="error">{error.message}</Alert>}
              {isIdle && <EngagementsSelect isDisabled />}
              {isSuccess && (
                <EngagementsSelect engagements={engagements} isDisabled={!watchVendor} />
              )}

              <RHFTextField name="firstName" label={t('forms.common.firstName')} />
              <RHFTextField name="lastName" label={t('forms.common.lastName')} />

              <RHFTextField name="email" label={t('forms.common.email')} />
              <RHFTextField name="title" label={t('forms.contacts.fields.title')} />
              {/* TODO: Address?? */}
            </Box>
            <PhoneNumbers />
          </Stack>
        </Card>
      </Grid>
    </Grid>
  );
}
