import React, { createContext, useContext, useMemo, useState } from 'react';

import { OrganizationResponse, UserResponse } from '@/client';
import useLocalStorage from '@/hooks/useLocalStorage';
import type { ISession } from '@/types';

interface IAppContext {
  currentOrganization: OrganizationResponse;
  setCurrentOrganization: (org: OrganizationResponse) => void;
  currentUser: UserResponse;
  setCurrentUser: (user: UserResponse) => void;
  session: ISession | undefined;
  setSession: (session: ISession | undefined) => void;
}

const AppContext = createContext<IAppContext | null>(null);

export const useAppContext = () => {
  const ctx = useContext(AppContext);
  if (!ctx) throw new Error('Context must be used inside a provider');
  return ctx;
};

export const AppContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [currentOrganization, setCurrentOrganization] =
    useLocalStorage<OrganizationResponse>('venddex-org');
  const [currentUser, setCurrentUser] = useLocalStorage<UserResponse>('venddex-user');
  const [session, setSession] = useState<ISession | undefined>();

  const contextValue: IAppContext = useMemo(
    () => ({
      currentOrganization,
      setCurrentOrganization,
      currentUser,
      setCurrentUser,
      session,
      setSession,
    }),
    [currentOrganization, setCurrentOrganization, currentUser, setCurrentUser, session, setSession],
  );

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};
