/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NoteResponse } from './NoteResponse';
import {
    NoteResponseFromJSON,
    NoteResponseFromJSONTyped,
    NoteResponseToJSON,
} from './NoteResponse';

/**
 * 
 * @export
 * @interface NotePageResponse
 */
export interface NotePageResponse {
    /**
     * 
     * @type {number}
     * @memberof NotePageResponse
     */
    totalCount: number;
    /**
     * 
     * @type {Array<NoteResponse>}
     * @memberof NotePageResponse
     */
    notes: Array<NoteResponse>;
}

/**
 * Check if a given object implements the NotePageResponse interface.
 */
export function instanceOfNotePageResponse(value: object): value is NotePageResponse {
    if (!('totalCount' in value) || value['totalCount'] === undefined) return false;
    if (!('notes' in value) || value['notes'] === undefined) return false;
    return true;
}

export function NotePageResponseFromJSON(json: any): NotePageResponse {
    return NotePageResponseFromJSONTyped(json, false);
}

export function NotePageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotePageResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'totalCount': json['totalCount'],
        'notes': ((json['notes'] as Array<any>).map(NoteResponseFromJSON)),
    };
}

export function NotePageResponseToJSON(value?: NotePageResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'totalCount': value['totalCount'],
        'notes': ((value['notes'] as Array<any>).map(NoteResponseToJSON)),
    };
}

