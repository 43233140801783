import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import LoadingButton from '@mui/lab/LoadingButton';
import { Alert } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { UserGroupSelect } from '@/components/forms';
import FormProvider from '@/components/hook-form';
import { LoadingScreen } from '@/components/loading-screen';
import { useGetUserById, useUpdateUserGroup } from '@/hooks/useUsers';
import { useAppContext } from '@/providers';
import { IUser } from '@/types';

const EditUserGroup = ({ onClose, user }: { onClose: VoidFunction; user: IUser }) => {
  const { currentOrganization } = useAppContext();

  const userGroup = user.userGroups.find(
    ({ organization }) => organization === currentOrganization.id,
  );

  const formMethods = useForm<{ userGroup: string }>({
    defaultValues: {
      userGroup: userGroup?._id,
    },
  });

  const { handleSubmit, reset } = formMethods;

  const { isPending, mutate: updateUserGroup } = useUpdateUserGroup();

  const onSubmit = handleSubmit((values) => {
    updateUserGroup(
      { userId: user._id, userGroupId: values.userGroup },
      {
        onSuccess: () => {
          reset();
          onClose();
        },
      },
    );
  });

  const { t } = useTranslation();

  return (
    <FormProvider methods={formMethods} onSubmit={onSubmit}>
      <DialogContent sx={{ py: 2 }}>
        <UserGroupSelect />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('buttons.cancel')}</Button>
        <LoadingButton loading={isPending} variant="contained" type="submit">
          {t('forms.updateUser.buttons.submit')}
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  );
};

export default function EditUserGroupModal({
  isOpen,
  onClose,
  userId,
}: {
  isOpen: boolean;
  onClose: VoidFunction;
  userId: string;
}) {
  const { t } = useTranslation();

  const { data: user, error, isLoading, isSuccess } = useGetUserById(userId);

  return (
    <Dialog fullWidth maxWidth="xs" open={isOpen} onClose={onClose}>
      <DialogTitle>{t('modals.editUser.title')}</DialogTitle>
      {error && (
        <DialogContent>
          <Alert severity="error">{error.message}</Alert>
        </DialogContent>
      )}
      {isLoading && (
        <DialogContent>
          <LoadingScreen />
        </DialogContent>
      )}
      {isSuccess && <EditUserGroup onClose={onClose} user={user} />}
    </Dialog>
  );
}
