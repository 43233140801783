import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useOrganizationsApi } from '@/api/useUsers';
import { type CreatePaymentMethod, OrganizationResponse } from '@/client';
import { useAppContext } from '@/providers';

const QUERY_KEY = (orgId: string) => ['payment-methods', orgId];

export const useGetPaymentMethods = () => {
  const organizationsApi = useOrganizationsApi();
  const {
    currentOrganization: { id: orgId },
  } = useAppContext();
  return useQuery({
    queryKey: QUERY_KEY(orgId),
    queryFn: () => organizationsApi.getPaymentMethods({ id: orgId }),
    enabled: !!orgId,
  });
};

export const useCreatePaymentMethod = () => {
  const organizationsApi = useOrganizationsApi();
  const {
    currentOrganization: { id },
  } = useAppContext();
  const queryClient = useQueryClient();
  return useMutation<OrganizationResponse, Error, CreatePaymentMethod>({
    mutationFn: ({ _default, paymentMethodId }) =>
      organizationsApi.createPaymentMethod({
        id,
        createPaymentMethod: { _default, paymentMethodId },
      }),

    onSuccess: ({ id: orgId }) => queryClient.invalidateQueries({ queryKey: QUERY_KEY(orgId) }),
  });
};
