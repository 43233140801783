import Cookies from 'js-cookie';

import { TOKEN_KEY } from '@/constants';
import { ICreateSessionRequest, ISession } from '@/types';

import { useHeaders } from './useHeaders';

const authEndpoint = `/api/v1/auth`;

export const createSession = async ({
  email,
  password,
}: ICreateSessionRequest): Promise<ISession> => {
  const response = await fetch(authEndpoint, {
    method: 'POST',
    body: JSON.stringify({
      email,
      password,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const { currentSession, error, message, user } = await response.json();

  if (error) {
    throw new Error(`${response.status} - ${message}`);
  }

  const token = response.headers.get('Token');
  Cookies.set(TOKEN_KEY, token as string);

  return { currentSession, error, user };
};

export const sendResetPasswordEmail = async (email: string): Promise<{ error: boolean }> => {
  const response = await fetch(`${authEndpoint}/password-reset`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({ email }),
  });

  const { error, message } = await response.json();

  if (error) {
    throw new Error(message);
  }

  return { error };
};

export const useAuthApi = () => {
  const headers = useHeaders();

  const deleteSession = async (): Promise<void> => {
    const response = await fetch(authEndpoint, {
      method: 'DELETE',
      headers,
    });

    const { error, message } = await response.json();

    if (error) {
      throw new Error(`${response.status} - ${message}`);
    }

    Cookies.remove(TOKEN_KEY);
  };

  return { deleteSession };
};
