/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FeatureResponse } from './FeatureResponse';
import {
    FeatureResponseFromJSON,
    FeatureResponseFromJSONTyped,
    FeatureResponseToJSON,
} from './FeatureResponse';

/**
 * 
 * @export
 * @interface SubscriptionResponse
 */
export interface SubscriptionResponse {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionResponse
     */
    id: string;
    /**
     * 
     * @type {Array<FeatureResponse>}
     * @memberof SubscriptionResponse
     */
    features: Array<FeatureResponse>;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionResponse
     */
    subscriptionPlanId: string;
}

/**
 * Check if a given object implements the SubscriptionResponse interface.
 */
export function instanceOfSubscriptionResponse(value: object): value is SubscriptionResponse {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('features' in value) || value['features'] === undefined) return false;
    if (!('subscriptionPlanId' in value) || value['subscriptionPlanId'] === undefined) return false;
    return true;
}

export function SubscriptionResponseFromJSON(json: any): SubscriptionResponse {
    return SubscriptionResponseFromJSONTyped(json, false);
}

export function SubscriptionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'features': ((json['features'] as Array<any>).map(FeatureResponseFromJSON)),
        'subscriptionPlanId': json['subscriptionPlanId'],
    };
}

export function SubscriptionResponseToJSON(value?: SubscriptionResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'features': ((value['features'] as Array<any>).map(FeatureResponseToJSON)),
        'subscriptionPlanId': value['subscriptionPlanId'],
    };
}

