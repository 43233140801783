import {
  IEngagement,
  IGetEngagementsQueryParams,
  IGetEngagementsResponse,
  IMutateEngagementRequest,
} from '@/types';
import { setSearchParams } from '@/utils/set-search-params';

import { useHeaders } from './useHeaders';

const engagementsEndpoint = '/api/v1/engagements';

export const useEngagementsApi = () => {
  const headers = useHeaders();

  const getEngagements = async (
    queryParams?: IGetEngagementsQueryParams,
  ): Promise<IGetEngagementsResponse> => {
    const searchParams = new URLSearchParams();
    setSearchParams(queryParams, searchParams);

    const url =
      searchParams.size === 0
        ? engagementsEndpoint
        : `${engagementsEndpoint}?${searchParams.toString()}`;

    const response = await fetch(url, {
      headers,
    });

    const { error, engagements, message, totalResults } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return { engagements, totalResults };
  };

  const getEngagementById = async (id: string): Promise<IEngagement> => {
    const response = await fetch(`${engagementsEndpoint}/${id}`, {
      headers,
    });

    const { error, engagement, message } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return engagement;
  };

  const createEngagement = async (
    createEngagementRequest: IMutateEngagementRequest,
  ): Promise<IEngagement> => {
    const response = await fetch(engagementsEndpoint, {
      method: 'POST',
      body: JSON.stringify(createEngagementRequest),
      headers,
    });

    const { error, engagement, message } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return engagement;
  };

  const updateEngagement = async (
    updateEngagementRequest: IMutateEngagementRequest,
  ): Promise<IEngagement> => {
    const { _id, description, frequency, label, status, type, vendor } = updateEngagementRequest;
    const response = await fetch(`${engagementsEndpoint}/${_id}`, {
      method: 'PATCH',
      body: JSON.stringify({ description, frequency, label, status, type, vendor }),
      headers,
    });

    const { error, engagement, message } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return engagement;
  };

  const deleteEngagement = async (id: string): Promise<void> => {
    const response = await fetch(`${engagementsEndpoint}/${id}`, {
      method: 'DELETE',
      headers,
    });

    const { error, message } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }
  };

  return {
    createEngagement,
    deleteEngagement,
    getEngagementById,
    getEngagements,
    updateEngagement,
  };
};
