import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export const useInviteUserSchema = () => {
  const { t } = useTranslation();
  return yup.object().shape({
    email: yup
      .string()
      .required(t('forms.common.emailRequired'))
      .email(t('forms.common.emailValid')),
    userGroup: yup.string().required(t('forms.common.userGroupValidation')),
  });
};
