import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import LoadingButton from '@mui/lab/LoadingButton';
import { Alert } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';

import { useCreatePaymentMethod } from '@/hooks/usePaymentMethods';
import { StripeProvider } from '@/providers';

const AddPaymentMethodModal = ({
  isDefault,
  onClose,
}: {
  isDefault: boolean;
  onClose: VoidFunction;
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const {
    mutate: createPaymentMethod,
    error: createPaymentMethodError,
    isPending,
  } = useCreatePaymentMethod();

  const { t } = useTranslation();

  const [isDefaultPaymentMethod, setIsDefaultPaymentMethod] = useState(isDefault);
  const [stripeError, setStripeError] = useState<string | undefined>();

  if (!stripe || !elements) {
    return null;
  }

  const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    evt.stopPropagation();

    const card = elements.getElement(CardElement);

    if (card) {
      try {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card,
        });

        if (error) {
          setStripeError(error.message);
          return;
        }

        if (paymentMethod.id) {
          createPaymentMethod(
            {
              _default: isDefaultPaymentMethod,
              paymentMethodId: paymentMethod.id,
            },
            {
              onSuccess: () => {
                onClose();
              },
            },
          );
        }
      } catch (e) {
        setStripeError(String(e));
      }
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open
      onClose={isPending ? () => true : onClose}
      disableEscapeKeyDown={isPending}
    >
      <DialogTitle>{t('modals.addPaymentMethod.title')}</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent sx={{ py: 2 }}>
          <Stack spacing={2}>
            <CardElement id="card-element" />
            <Stack direction="row" justifyContent="flex-end">
              <FormControlLabel
                control={
                  <Switch
                    checked={isDefaultPaymentMethod}
                    onChange={(evt) => setIsDefaultPaymentMethod(evt.target.checked)}
                  />
                }
                label={t('forms.addPaymentMethod.defaultPaymentMethod')}
                labelPlacement="start"
              />
            </Stack>
            {(createPaymentMethodError || stripeError) && (
              <Alert severity="error">{createPaymentMethodError?.message || stripeError}</Alert>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t('buttons.cancel')}</Button>
          <LoadingButton loading={isPending} variant="contained" type="submit">
            {t('forms.addPaymentMethod.buttons.submit')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default function AddPaymentMethod({
  isDefault = false,
  onClose,
}: {
  isDefault?: boolean;
  onClose: VoidFunction;
}) {
  return (
    <StripeProvider>
      <AddPaymentMethodModal isDefault={isDefault} onClose={onClose} />
    </StripeProvider>
  );
}
