import {
  ICreateVendorRequest,
  IGetVendorsQueryParams,
  IGetVendorsResponse,
  IUpdateVendorRequest,
  IVendor,
} from '@/types';
import { setSearchParams } from '@/utils/set-search-params';

import { useHeaders } from './useHeaders';

const vendorsEndpoint = '/api/vendors';

export const useVendorsApi = () => {
  const headers = useHeaders();

  const getVendors = async (queryParams?: IGetVendorsQueryParams): Promise<IGetVendorsResponse> => {
    const searchParams = new URLSearchParams();
    setSearchParams(queryParams, searchParams);

    const url =
      searchParams.size === 0 ? vendorsEndpoint : `${vendorsEndpoint}?${searchParams.toString()}`;

    const response = await fetch(url, {
      headers,
    });

    const { error, message, totalResults, vendors } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return { totalResults, vendors };
  };

  const getVendorById = async (id: string): Promise<IVendor> => {
    const response = await fetch(`${vendorsEndpoint}/${id}`, {
      headers,
    });

    const { error, message, vendor } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return vendor;
  };

  const createVendor = async (createVendorRequest: ICreateVendorRequest): Promise<IVendor> => {
    const response = await fetch(vendorsEndpoint, {
      method: 'POST',
      body: JSON.stringify(createVendorRequest),
      headers,
    });

    const { error, message, vendor } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return vendor;
  };

  const updateVendor = async (updateVendorRequest: IUpdateVendorRequest): Promise<IVendor> => {
    const { _id, label, status, tags } = updateVendorRequest;
    const response = await fetch(`${vendorsEndpoint}/${_id}`, {
      method: 'PATCH',
      body: JSON.stringify({ label, status, tags }),
      headers,
    });

    const { error, message, vendor } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return vendor;
  };

  const deleteVendor = async (id: string): Promise<IVendor> => {
    const response = await fetch(`${vendorsEndpoint}/${id}`, {
      method: 'DELETE',
      headers,
    });

    const { error, message, vendor } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return vendor;
  };

  return {
    getVendors,
    getVendorById,
    createVendor,
    updateVendor,
    deleteVendor,
  };
};
