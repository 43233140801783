import { m } from 'framer-motion';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { OrganizationResponse, UserResponse } from '@/client';
import CustomPopover, { usePopover } from '@/components/custom-popover';
import { InviteUserModal } from '@/components/modals';
import { PATHS } from '@/constants';
import { useBoolean } from '@/hooks/use-boolean';
import { useAppContext } from '@/providers';

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();

  const { currentUser, setCurrentOrganization, setCurrentUser } = useAppContext();

  const fullName = useMemo(
    () => `${currentUser?.firstName} ${currentUser?.lastName}`,
    [currentUser],
  );

  const popover = usePopover();

  const inviteUserModal = useBoolean();

  const { t } = useTranslation();

  const OPTIONS = [
    {
      label: 'Home',
      onClick: () => navigate('/'),
    },
    {
      label: t('buttons.account'),
      onClick: () => navigate(PATHS.account),
    },
    {
      label: 'Settings',
      onClick: () => navigate('/'),
    },
    {
      label: t('buttons.invite'),
      onClick: inviteUserModal.onTrue,
    },
  ];

  const handleLogout = () => {
    setCurrentOrganization({} as OrganizationResponse);
    setCurrentUser({} as UserResponse);
  };

  const handleClickItem = (onClick: VoidFunction) => {
    popover.onClose();
    onClick();
  };

  return (
    <>
      <IconButton
        aria-label="account-menu"
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          alt={fullName}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        >
          {currentUser?.firstName?.charAt(0).toUpperCase()}
        </Avatar>
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography variant="subtitle2" noWrap>
            {fullName}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {currentUser?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.onClick)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={handleLogout}
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
        >
          {t('buttons.signOut')}
        </MenuItem>
      </CustomPopover>

      <InviteUserModal isOpen={inviteUserModal.value} onClose={inviteUserModal.onFalse} />
    </>
  );
}
