/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateOrganizationRequest
 */
export interface CreateOrganizationRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationRequest
     */
    label: string;
}

/**
 * Check if a given object implements the CreateOrganizationRequest interface.
 */
export function instanceOfCreateOrganizationRequest(value: object): value is CreateOrganizationRequest {
    if (!('label' in value) || value['label'] === undefined) return false;
    return true;
}

export function CreateOrganizationRequestFromJSON(json: any): CreateOrganizationRequest {
    return CreateOrganizationRequestFromJSONTyped(json, false);
}

export function CreateOrganizationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateOrganizationRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'label': json['label'],
    };
}

export function CreateOrganizationRequestToJSON(value?: CreateOrganizationRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'label': value['label'],
    };
}

