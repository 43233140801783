import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import SvgColor from '@/components/svg-color';
import { PATHS } from '@/constants';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useTranslation();
  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        items: [
          {
            title: t('nav.vendors'),
            path: PATHS.base,
            icon: ICONS.dashboard,
          },
          {
            title: t('nav.prospects'),
            path: PATHS.prospects,
            icon: ICONS.menuItem,
          },
          {
            title: t('nav.engagements'),
            path: PATHS.engagements,
            icon: ICONS.job,
          },
          {
            title: t('nav.contacts'),
            path: PATHS.contacts,
            icon: ICONS.user,
          },
          {
            title: t('nav.documents'),
            path: PATHS.documents,
            icon: ICONS.file,
          },
          {
            title: t('nav.notifications'),
            path: PATHS.notifications,
            icon: ICONS.blank,
          },
          {
            title: t('nav.users'),
            path: PATHS.users,
            icon: ICONS.user,
          },
          {
            title: t('nav.support'),
            path: PATHS.support,
            icon: ICONS.blank,
          },
        ],
      },
    ],
    [t],
  );

  return data;
}
