import { ICreatePaymentMethod } from '@/types';

import { useHeaders } from './useHeaders';

export const usePaymentMethodsApi = () => {
  const headers = useHeaders();

  const getPaymentMethods = async (orgId: string) => {
    const response = await fetch(`/api/v1/organizations/${orgId}/payment-methods`, {
      headers,
    });

    const { error, message, paymentMethods } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return paymentMethods;
  };

  const createPaymentMethod = async ({
    isDefault,
    orgId,
    paymentMethodId,
  }: ICreatePaymentMethod) => {
    const response = await fetch(`/api/v1/organizations/${orgId}/payment-methods`, {
      headers,
      method: 'POST',
      body: JSON.stringify({ isDefault, paymentMethodId }),
    });

    const { error, message, paymentMethod: pmtId } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return { paymentMethodId: pmtId };
  };

  return {
    getPaymentMethods,
    createPaymentMethod,
  };
};
