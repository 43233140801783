import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useNotesApi } from '@/api';
import { ICreateNoteRequest, INote } from '@/types';

const QUERY_KEY = (parentId: string) => ['notes', parentId];

export const useGetNotes = (parentId: string) => {
  const { getNotes } = useNotesApi();
  return useQuery<INote[], Error>({
    queryKey: QUERY_KEY(parentId),
    queryFn: () => getNotes({ parentId }),
  });
};

export const useCreateNote = () => {
  const { createNote } = useNotesApi();
  const queryClient = useQueryClient();
  return useMutation<INote, Error, ICreateNoteRequest>({
    mutationFn: (createNoteRequest) => createNote(createNoteRequest),

    onSuccess: ({ parentId }) => queryClient.invalidateQueries({ queryKey: QUERY_KEY(parentId) }),
  });
};
//
// export const useGetCommentById = (id: string) => {
//   const { getCommentById } = useCommentsApi();
//   return useQuery<IComment, Error>(QUERY_KEY(id), () => getCommentById(id), { enabled: !!id });
// };
//
// export const useUpdateComment = () => {
//   const { updateComment } = useCommentsApi();
//   const queryClient = useQueryClient();
//   return useMutation<IComment, Error, { id: string; text: string }>(
//     ({ id, text }) => updateComment({ id, text }),
//     {
//       onSuccess: ({ _id, parent }) => {
//         queryClient.invalidateQueries(QUERY_KEY(_id));
//         queryClient.invalidateQueries(QUERY_KEY(parent));
//       },
//     },
//   );
// };
//
// export const useDeleteComment = () => {
//   const queryClient = useQueryClient();
//   const { deleteComment } = useCommentsApi();
//   return useMutation<IComment, Error, { id: string }, unknown>(({ id }) => deleteComment(id), {
//     onSuccess: ({ parent }) => {
//       queryClient.invalidateQueries(QUERY_KEY(parent));
//     },
//   });
// };
