/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateNoteRequest,
  ErrorResponse,
  NotePageResponse,
  NoteResponse,
} from '../models/index';
import {
    CreateNoteRequestFromJSON,
    CreateNoteRequestToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    NotePageResponseFromJSON,
    NotePageResponseToJSON,
    NoteResponseFromJSON,
    NoteResponseToJSON,
} from '../models/index';

export interface CreateNoteOperationRequest {
    createNoteRequest: CreateNoteRequest;
}

export interface DeleteNoteRequest {
    id: string;
}

export interface GetNotesRequest {
    entityType: GetNotesEntityTypeEnum;
    entityId: string;
    page?: number;
    size?: number;
}

export interface UpdateNoteRequest {
    id: string;
    createNoteRequest: CreateNoteRequest;
}

/**
 * 
 */
export class NotesApi extends runtime.BaseAPI {

    /**
     * Create a new Note for an EntityType
     */
    async createNoteRaw(requestParameters: CreateNoteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NoteResponse>> {
        if (requestParameters['createNoteRequest'] == null) {
            throw new runtime.RequiredError(
                'createNoteRequest',
                'Required parameter "createNoteRequest" was null or undefined when calling createNote().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateNoteRequestToJSON(requestParameters['createNoteRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NoteResponseFromJSON(jsonValue));
    }

    /**
     * Create a new Note for an EntityType
     */
    async createNote(requestParameters: CreateNoteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NoteResponse> {
        const response = await this.createNoteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a Note
     */
    async deleteNoteRaw(requestParameters: DeleteNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NoteResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteNote().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NoteResponseFromJSON(jsonValue));
    }

    /**
     * Delete a Note
     */
    async deleteNote(requestParameters: DeleteNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NoteResponse> {
        const response = await this.deleteNoteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Notes for an EntityType
     */
    async getNotesRaw(requestParameters: GetNotesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotePageResponse>> {
        if (requestParameters['entityType'] == null) {
            throw new runtime.RequiredError(
                'entityType',
                'Required parameter "entityType" was null or undefined when calling getNotes().'
            );
        }

        if (requestParameters['entityId'] == null) {
            throw new runtime.RequiredError(
                'entityId',
                'Required parameter "entityId" was null or undefined when calling getNotes().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['entityType'] != null) {
            queryParameters['entityType'] = requestParameters['entityType'];
        }

        if (requestParameters['entityId'] != null) {
            queryParameters['entityId'] = requestParameters['entityId'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotePageResponseFromJSON(jsonValue));
    }

    /**
     * Get Notes for an EntityType
     */
    async getNotes(requestParameters: GetNotesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotePageResponse> {
        const response = await this.getNotesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a Note
     */
    async updateNoteRaw(requestParameters: UpdateNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NoteResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateNote().'
            );
        }

        if (requestParameters['createNoteRequest'] == null) {
            throw new runtime.RequiredError(
                'createNoteRequest',
                'Required parameter "createNoteRequest" was null or undefined when calling updateNote().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CreateNoteRequestToJSON(requestParameters['createNoteRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NoteResponseFromJSON(jsonValue));
    }

    /**
     * Update a Note
     */
    async updateNote(requestParameters: UpdateNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NoteResponse> {
        const response = await this.updateNoteRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetNotesEntityTypeEnum = {
    Engagement: 'ENGAGEMENT',
    Vendor: 'VENDOR'
} as const;
export type GetNotesEntityTypeEnum = typeof GetNotesEntityTypeEnum[keyof typeof GetNotesEntityTypeEnum];
