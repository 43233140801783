import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';

import { IEngagement } from '@/types';

export default function EngagementsSelect({
  engagements,
  isDisabled,
}: {
  engagements?: IEngagement[];
  isDisabled: boolean;
}) {
  const { t } = useTranslation();

  const { control, setValue } = useFormContext();
  return (
    <Controller
      name="engagements"
      control={control}
      render={({ field }) => (
        <Autocomplete
          {...field}
          disabled={isDisabled}
          multiple
          limitTags={1}
          id="tags-filled"
          options={engagements?.map(({ label }) => label) || []}
          onChange={(event, newValue) => setValue('engagements', newValue)}
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: string, index: number) => {
              const { key, ...tagProps } = getTagProps({ index });
              return <Chip size="small" label={option} key={index} {...tagProps} />;
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('forms.common.engagements')}
              placeholder={t('forms.common.engagementsPlaceholder')}
            />
          )}
        />
      )}
    />
  );
}
