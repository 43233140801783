import { ReactNode, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { PATHS } from '@/constants';
import { useAppContext } from '@/providers';

export default function AuthGuard({ children }: { children: ReactNode }) {
  const navigate = useNavigate();
  const { currentOrganization, currentUser } = useAppContext();
  const { token } = currentUser;

  useEffect(() => {
    if (!token) {
      navigate('./login');
    }

    // posthog.identify(currentUser.email);

    if (currentUser?.organizations?.length === 0 && !currentOrganization.id) {
      navigate(PATHS.onboarding);
    }
  }, [currentUser, currentOrganization, navigate, token]);

  if (currentUser?.organizations?.length === 0 && !currentOrganization.id) {
    return <Outlet />;
  }

  return <>{children}</>;
}
