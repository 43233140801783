type QueryParams = { [key: string]: string | number | boolean | null | undefined };

export function setSearchParams(
  queryParams: QueryParams | undefined,
  searchParams: URLSearchParams,
) {
  if (queryParams) {
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== '') {
        searchParams.append(key, String(value));
      }
    });
  }
}
