import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';

import FormProvider, { RHFTextField } from '@/components/hook-form';
import { useCreateNote } from '@/hooks/useNotes';

// TODO: INT

export default function AddNote({
  parentId,
  parentModel,
}: {
  parentId: string;
  parentModel: 'Contact' | 'Engagement' | 'Prospect' | 'Vendor';
}) {
  const { isPending, mutate: createNote } = useCreateNote();

  const formMethods = useForm({
    defaultValues: {
      comment: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        comment: yup.string().required(''),
      }),
    ),
  });

  const { handleSubmit, reset } = formMethods;

  const onSubmit = handleSubmit(({ comment }) => {
    createNote({ comment, parentId, parentModel }, { onSuccess: () => reset() });
  });

  const { t } = useTranslation();

  return (
    <FormProvider methods={formMethods} onSubmit={onSubmit}>
      <Stack spacing={2} direction="row" alignItems="center">
        <RHFTextField
          disabled={isPending}
          hideErrorMessage
          name="comment"
          placeholder={t('forms.notes.fields.text.placeholder')}
          multiline
          size="small"
        />
        <LoadingButton loading={isPending} type="submit" variant="contained">
          {t('forms.notes.buttons.create')}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
