import { IGetUserGroupsResponse } from '@/types';

import { useHeaders } from './useHeaders';

const userGroupsEndpoint = `/api/v1/user-groups`;

export const useUserGroupsApi = () => {
  const headers = useHeaders();

  const getUserGroups = async (): Promise<IGetUserGroupsResponse> => {
    const response = await fetch(userGroupsEndpoint, {
      headers,
    });

    const { error, message, userGroups } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return { userGroups };
  };

  return { getUserGroups };
};
