import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import posthog from 'posthog-js';

import { createSession, useAuthApi, useSessionsApi } from '@/api';
import { useUsersApi } from '@/api/useUsers';
import { LoginRequest, OrganizationResponse } from '@/client';
import { useAppContext } from '@/providers';
import { ICreateSessionRequest, ISession } from '@/types';

import { useError } from './useError';

export const QUERY_KEY = () => ['sessions'];

export const useGetSession = (enabled?: boolean) => {
  const { getSession } = useSessionsApi();
  return useQuery({
    queryFn: () => getSession(),
    queryKey: QUERY_KEY(),
    enabled,
  });
};

export const useCreateSession = () => {
  const queryClient = useQueryClient();
  return useMutation<ISession, Error, ICreateSessionRequest, Error>({
    mutationFn: (createSessionRequest) => createSession(createSessionRequest),
    onSuccess: ({ user }) => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEY() });
      posthog.identify(user.email);
    },
  });
};

export const useDeleteSession = () => {
  const { deleteSession } = useAuthApi();
  const { setCurrentOrganization, setSession } = useAppContext();
  const { reportError } = useError();
  return useMutation({
    mutationFn: () => deleteSession(),
    onSuccess: () => {
      setCurrentOrganization({} as OrganizationResponse);
      setSession(undefined);
    },
    onError: (error) => reportError(error),
  });
};

export const useLogin = () => {
  const usersApi = useUsersApi();
  const { reportError } = useError();
  return useMutation({
    mutationFn: (loginRequest: LoginRequest) => usersApi.login({ loginRequest }),
    onError: (error) => reportError(error),
  });
};
