/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EngagementResponse } from './EngagementResponse';
import {
    EngagementResponseFromJSON,
    EngagementResponseFromJSONTyped,
    EngagementResponseToJSON,
} from './EngagementResponse';

/**
 * 
 * @export
 * @interface VendorResponse
 */
export interface VendorResponse {
    /**
     * 
     * @type {string}
     * @memberof VendorResponse
     */
    id: string;
    /**
     * 
     * @type {Array<EngagementResponse>}
     * @memberof VendorResponse
     */
    engagements?: Array<EngagementResponse>;
    /**
     * 
     * @type {string}
     * @memberof VendorResponse
     */
    label: string;
}

/**
 * Check if a given object implements the VendorResponse interface.
 */
export function instanceOfVendorResponse(value: object): value is VendorResponse {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    return true;
}

export function VendorResponseFromJSON(json: any): VendorResponse {
    return VendorResponseFromJSONTyped(json, false);
}

export function VendorResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VendorResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'engagements': json['engagements'] == null ? undefined : ((json['engagements'] as Array<any>).map(EngagementResponseFromJSON)),
        'label': json['label'],
    };
}

export function VendorResponseToJSON(value?: VendorResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'engagements': value['engagements'] == null ? undefined : ((value['engagements'] as Array<any>).map(EngagementResponseToJSON)),
        'label': value['label'],
    };
}

