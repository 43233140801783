import { useState } from 'react';

const getStoredItem = (key: string) => {
  try {
    const item = window.localStorage.getItem(key);
    return item && item !== 'undefined' ? JSON.parse(item) : {};
  } catch (error) {
    throw new Error(`Unable to get ${key} from local storage - ${error}`);
  }
};

const useLocalStorage = <T>(key: string): [T, (value: T) => void] => {
  const [value, setLocalStorage] = useState<T>(getStoredItem(key));
  const setValue = (newValue: T) => {
    try {
      window.localStorage.setItem(key, JSON.stringify(newValue));
      setLocalStorage(newValue);
    } catch {
      throw new Error(`Unable to set ${key} to local storage`);
    }
  };

  return [value, setValue];
};

export default useLocalStorage;
