import { useInfiniteQuery } from '@tanstack/react-query';

import { useSearchApi } from '@/api';
import { ISearchResponse } from '@/types';

const QUERY_KEY = (searchTerm?: string) => ['search', searchTerm];

export const useSearch = (searchTerm: string) => {
  const { search } = useSearchApi();
  return useInfiniteQuery<ISearchResponse, Error>({
    queryKey: QUERY_KEY(searchTerm),
    queryFn: ({ pageParam = 0 }) => search({ page: pageParam as string, searchTerm }),
    enabled: !!searchTerm,
    getNextPageParam: (lastPage, allPages) => {
      const { contacts, engagements, vendors } = lastPage;
      return contacts.results.length === 20 ||
        engagements.results.length === 20 ||
        vendors.results.length === 20
        ? allPages.length
        : undefined;
    },
    initialPageParam: 0,
    // staleTime: 0,
    // cacheTime: 0,
  });
};
