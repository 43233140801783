import { useQuery } from '@tanstack/react-query';

import { useTagsApi } from '@/api';
import { useAppContext } from '@/providers';
import { IGetTagsResponse } from '@/types';

export const QUERY_KEY = (orgId: string) => ['tags', orgId];

export const useGetTags = () => {
  const { getTags } = useTagsApi();
  const { currentOrganization } = useAppContext();
  return useQuery<IGetTagsResponse, Error>({
    queryKey: QUERY_KEY(currentOrganization.id),
    queryFn: () => getTags(),
    enabled: !!currentOrganization.id,
  });
};
