import { useTranslation } from 'react-i18next';

import Stack from '@mui/material/Stack';

import { RHFTextField } from '@/components/hook-form';

// import TagsSelect from './TagsSelect';

export default function VendorFields() {
  // const { data: organization, error, isLoading, isSuccess } = useGetOrganization();

  // const statusOptions = useStatusOptions(
  //   organization?.statusOptions || [],
  //   StatusOptionTypeEnum.VENDOR,
  // );

  const { t } = useTranslation();

  // if (isLoading) {
  //   return <LoadingScreen />;
  // }
  //
  // if (error) {
  //   return <Alert severity="error">{error.message}</Alert>;
  // }
  //
  // if (isSuccess) {
  return (
    <Stack spacing={3}>
      <RHFTextField name="label" label={t('forms.vendors.fields.label')} />
      {/* <RHFSelect */}
      {/*   name="status" */}
      {/*   label={t('forms.vendors.fields.status')} */}
      {/*   placeholder={t('forms.vendors.fields.statusPlaceholder')} */}
      {/* > */}
      {/*   {statusOptions.map((option) => ( */}
      {/*     <MenuItem key={option._id} value={option._id}> */}
      {/*       {option.label} */}
      {/*     </MenuItem> */}
      {/*   ))} */}
      {/* </RHFSelect> */}
      {/* <TagsSelect /> */}
    </Stack>
  );
  // }
}
