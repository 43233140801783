/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FeatureResponse } from './FeatureResponse';
import {
    FeatureResponseFromJSON,
    FeatureResponseFromJSONTyped,
    FeatureResponseToJSON,
} from './FeatureResponse';

/**
 * 
 * @export
 * @interface SubscriptionPlanResponse
 */
export interface SubscriptionPlanResponse {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanResponse
     */
    id: string;
    /**
     * 
     * @type {Array<FeatureResponse>}
     * @memberof SubscriptionPlanResponse
     */
    features: Array<FeatureResponse>;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanResponse
     */
    label: string;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionPlanResponse
     */
    price: number;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionPlanResponse
     */
    isDefault?: boolean;
}

/**
 * Check if a given object implements the SubscriptionPlanResponse interface.
 */
export function instanceOfSubscriptionPlanResponse(value: object): value is SubscriptionPlanResponse {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('features' in value) || value['features'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('price' in value) || value['price'] === undefined) return false;
    return true;
}

export function SubscriptionPlanResponseFromJSON(json: any): SubscriptionPlanResponse {
    return SubscriptionPlanResponseFromJSONTyped(json, false);
}

export function SubscriptionPlanResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionPlanResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'features': ((json['features'] as Array<any>).map(FeatureResponseFromJSON)),
        'label': json['label'],
        'price': json['price'],
        'isDefault': json['isDefault'] == null ? undefined : json['isDefault'],
    };
}

export function SubscriptionPlanResponseToJSON(value?: SubscriptionPlanResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'features': ((value['features'] as Array<any>).map(FeatureResponseToJSON)),
        'label': value['label'],
        'price': value['price'],
        'isDefault': value['isDefault'],
    };
}

