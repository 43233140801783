import { useHeaders } from './useHeaders';

export const useSubscriptionsApi = () => {
  const headers = useHeaders();

  const getSubscriptions = async (orgId: string) => {
    const response = await fetch(`/api/v1/organizations/${orgId}/subscriptions`, {
      headers,
    });

    const { error, message, subscriptions } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return subscriptions;
  };

  const createSubscription = async ({
    orgId,
    subscriptionPlanId,
  }: {
    orgId: string;
    subscriptionPlanId: string;
  }) => {
    const response = await fetch(`/api/v1/organizations/${orgId}/subscriptions`, {
      headers,
      method: 'POST',
      body: JSON.stringify({ subscriptionPlanId }),
    });

    const { error, message, subscriptions } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return subscriptions;
  };

  const previewSubscription = async ({
    orgId,
    subscriptionPlanId,
  }: {
    orgId: string;
    subscriptionPlanId: string;
  }) => {
    const response = await fetch(`/api/v1/organizations/${orgId}/subscriptions/preview`, {
      headers,
      method: 'POST',
      body: JSON.stringify({ subscriptionPlanId }),
    });

    const { error, message, previewInvoice } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return previewInvoice;
  };

  const getSubscription = async ({
    orgId,
    subscriptionId,
  }: {
    orgId: string;
    subscriptionId: string;
  }) => {
    const response = await fetch(`/api/v1/organizations/${orgId}/subscriptions/${subscriptionId}`, {
      headers,
    });

    const { error, message, subscription } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return subscription;
  };

  return {
    createSubscription,
    getSubscriptions,
    getSubscription,
    previewSubscription,
  };
};
