import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Alert } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Unstable_Grid2';

import { AddressFields, VendorFields } from '@/components/forms';
import FormProvider from '@/components/hook-form';
import { LoadingScreen } from '@/components/loading-screen';
import { useGetTags } from '@/hooks/useTags';
import { useGetVendorById, useUpdateVendor } from '@/hooks/useVendors';

export default function EditVendorModal({
  isOpen,
  onClose,
  vendorId,
}: {
  isOpen: boolean;
  onClose: VoidFunction;
  vendorId: string;
}) {
  const { t } = useTranslation();

  const { data: vendor, error, isLoading, isSuccess } = useGetVendorById(vendorId);

  const formMethods = useForm({
    defaultValues: {
      label: vendor?.label || '',
      status: vendor?.status._id || '',
      tags: vendor?.tags.map(({ value }) => value) || [],
    },
  });

  const { handleSubmit, reset } = formMethods;

  const { isPending, mutate: updateVendor } = useUpdateVendor();

  const { data: tagsResponse } = useGetTags();

  const onSubmit = handleSubmit((values) => {
    const tags = values.tags.map((tag) => tagsResponse?.tagsMap.get(tag) || tag);

    const _id = vendor?._id as string;

    updateVendor(
      { _id, ...values, tags },
      {
        onSuccess: () => {
          onClose();
          reset();
        },
      },
    );
  });

  return (
    <Dialog open={isOpen} maxWidth="lg" onClose={onClose} fullWidth>
      <DialogTitle>{t('modals.editVendor.title')}</DialogTitle>
      <FormProvider methods={formMethods} onSubmit={onSubmit}>
        <DialogContent>
          {error && <Alert severity="error">{error.message}</Alert>}
          {isLoading && <LoadingScreen />}
          {isSuccess && (
            <Grid container columnSpacing={2}>
              <Grid xs={12} md={6}>
                <VendorFields />
              </Grid>
              <AddressFields />
            </Grid>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>{t('buttons.cancel')}</Button>
          <Button disabled={isLoading || isPending} type="submit" variant="contained">
            {t('forms.vendors.buttons.update')}
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
