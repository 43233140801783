import { ICreateNoteRequest, IGetNotesQueryParams, INote, IUpdateNoteRequest } from '@/types';
import { setSearchParams } from '@/utils/set-search-params';

import { useHeaders } from './useHeaders';

const notesEndpoint = '/api/v1/notes';

export const useNotesApi = () => {
  const headers = useHeaders();

  const getNotes = async (queryParams: IGetNotesQueryParams): Promise<INote[]> => {
    const searchParams = new URLSearchParams();
    setSearchParams(queryParams, searchParams);

    const response = await fetch(`${notesEndpoint}?${searchParams.toString()}`, {
      headers,
    });

    const { error, message, notes } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return notes;
  };

  const createNote = async ({
    comment,
    parentId,
    parentModel,
  }: ICreateNoteRequest): Promise<INote> => {
    const response = await fetch(notesEndpoint, {
      method: 'POST',
      body: JSON.stringify({ comment, parentId, parentModel }),
      headers,
    });

    const { error, message, note } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return note;
  };

  const updateNote = async ({ _id, comment }: IUpdateNoteRequest): Promise<INote> => {
    const response = await fetch(`${notesEndpoint}/${_id}`, {
      method: 'PATCH',
      body: JSON.stringify({ comment }),
      headers,
    });

    const { error, message, note } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return note;
  };

  const deleteNote = async (id: string): Promise<INote> => {
    const response = await fetch(`${notesEndpoint}/${id}`, {
      method: 'DELETE',
      headers,
    });

    const { error, message, note } = await response.json();

    if (error) {
      throw new Error(`${response.status}: ${message}`);
    }

    return note;
  };

  return {
    getNotes,
    createNote,
    updateNote,
    deleteNote,
  };
};
