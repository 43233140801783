/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateOrganizationRequest,
  CreatePaymentMethod,
  ErrorResponse,
  OrganizationResponse,
  PaymentMethodResponse,
  PreviewSubscriptionResponse,
  UpdateSubscriptionRequest,
} from '../models/index';
import {
    CreateOrganizationRequestFromJSON,
    CreateOrganizationRequestToJSON,
    CreatePaymentMethodFromJSON,
    CreatePaymentMethodToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    OrganizationResponseFromJSON,
    OrganizationResponseToJSON,
    PaymentMethodResponseFromJSON,
    PaymentMethodResponseToJSON,
    PreviewSubscriptionResponseFromJSON,
    PreviewSubscriptionResponseToJSON,
    UpdateSubscriptionRequestFromJSON,
    UpdateSubscriptionRequestToJSON,
} from '../models/index';

export interface CreateOrganizationOperationRequest {
    createOrganizationRequest: CreateOrganizationRequest;
}

export interface CreatePaymentMethodRequest {
    id: string;
    createPaymentMethod: CreatePaymentMethod;
}

export interface CreateSubscriptionPreviewRequest {
    id: string;
    updateSubscriptionRequest: UpdateSubscriptionRequest;
}

export interface GetOrganizationByIdRequest {
    id: string;
}

export interface GetPaymentMethodsRequest {
    id: string;
}

export interface UpdateSubscriptionOperationRequest {
    id: string;
    updateSubscriptionRequest: UpdateSubscriptionRequest;
}

/**
 * 
 */
export class OrganizationsApi extends runtime.BaseAPI {

    /**
     * Create a new Organization
     */
    async createOrganizationRaw(requestParameters: CreateOrganizationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationResponse>> {
        if (requestParameters['createOrganizationRequest'] == null) {
            throw new runtime.RequiredError(
                'createOrganizationRequest',
                'Required parameter "createOrganizationRequest" was null or undefined when calling createOrganization().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organizations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOrganizationRequestToJSON(requestParameters['createOrganizationRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationResponseFromJSON(jsonValue));
    }

    /**
     * Create a new Organization
     */
    async createOrganization(requestParameters: CreateOrganizationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationResponse> {
        const response = await this.createOrganizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add a Payment Method to an Organization
     */
    async createPaymentMethodRaw(requestParameters: CreatePaymentMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling createPaymentMethod().'
            );
        }

        if (requestParameters['createPaymentMethod'] == null) {
            throw new runtime.RequiredError(
                'createPaymentMethod',
                'Required parameter "createPaymentMethod" was null or undefined when calling createPaymentMethod().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organizations/{id}/payment-methods`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePaymentMethodToJSON(requestParameters['createPaymentMethod']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationResponseFromJSON(jsonValue));
    }

    /**
     * Add a Payment Method to an Organization
     */
    async createPaymentMethod(requestParameters: CreatePaymentMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationResponse> {
        const response = await this.createPaymentMethodRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Preview a Subscription Change
     */
    async createSubscriptionPreviewRaw(requestParameters: CreateSubscriptionPreviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PreviewSubscriptionResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling createSubscriptionPreview().'
            );
        }

        if (requestParameters['updateSubscriptionRequest'] == null) {
            throw new runtime.RequiredError(
                'updateSubscriptionRequest',
                'Required parameter "updateSubscriptionRequest" was null or undefined when calling createSubscriptionPreview().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organizations/{id}/subscriptions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSubscriptionRequestToJSON(requestParameters['updateSubscriptionRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PreviewSubscriptionResponseFromJSON(jsonValue));
    }

    /**
     * Preview a Subscription Change
     */
    async createSubscriptionPreview(requestParameters: CreateSubscriptionPreviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PreviewSubscriptionResponse> {
        const response = await this.createSubscriptionPreviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get an Organization by ID
     */
    async getOrganizationByIdRaw(requestParameters: GetOrganizationByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getOrganizationById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organizations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationResponseFromJSON(jsonValue));
    }

    /**
     * Get an Organization by ID
     */
    async getOrganizationById(requestParameters: GetOrganizationByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationResponse> {
        const response = await this.getOrganizationByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Payment Methods for an Organization
     */
    async getPaymentMethodsRaw(requestParameters: GetPaymentMethodsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PaymentMethodResponse>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getPaymentMethods().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organizations/{id}/payment-methods`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PaymentMethodResponseFromJSON));
    }

    /**
     * Get Payment Methods for an Organization
     */
    async getPaymentMethods(requestParameters: GetPaymentMethodsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PaymentMethodResponse>> {
        const response = await this.getPaymentMethodsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an Organization\'s Subscription
     */
    async updateSubscriptionRaw(requestParameters: UpdateSubscriptionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateSubscription().'
            );
        }

        if (requestParameters['updateSubscriptionRequest'] == null) {
            throw new runtime.RequiredError(
                'updateSubscriptionRequest',
                'Required parameter "updateSubscriptionRequest" was null or undefined when calling updateSubscription().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organizations/{id}/subscriptions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSubscriptionRequestToJSON(requestParameters['updateSubscriptionRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationResponseFromJSON(jsonValue));
    }

    /**
     * Update an Organization\'s Subscription
     */
    async updateSubscription(requestParameters: UpdateSubscriptionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationResponse> {
        const response = await this.updateSubscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
