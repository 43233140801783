/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreatePaymentMethod
 */
export interface CreatePaymentMethod {
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentMethod
     */
    paymentMethodId: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePaymentMethod
     */
    _default?: boolean;
}

/**
 * Check if a given object implements the CreatePaymentMethod interface.
 */
export function instanceOfCreatePaymentMethod(value: object): value is CreatePaymentMethod {
    if (!('paymentMethodId' in value) || value['paymentMethodId'] === undefined) return false;
    return true;
}

export function CreatePaymentMethodFromJSON(json: any): CreatePaymentMethod {
    return CreatePaymentMethodFromJSONTyped(json, false);
}

export function CreatePaymentMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePaymentMethod {
    if (json == null) {
        return json;
    }
    return {
        
        'paymentMethodId': json['paymentMethodId'],
        '_default': json['default'] == null ? undefined : json['default'],
    };
}

export function CreatePaymentMethodToJSON(value?: CreatePaymentMethod | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'paymentMethodId': value['paymentMethodId'],
        'default': value['_default'],
    };
}

