import { Suspense, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import AuthGuard from '@/components/AuthGuard';
import { LoadingScreen, SplashScreen } from '@/components/loading-screen';
import { useScrollToTop } from '@/hooks/use-scroll-to-top';
import { useGetOrganization } from '@/hooks/useOrganizations';
import Layout from '@/layouts/main';
import { useAppContext } from '@/providers';

export default function App() {
  const { currentOrganization, setCurrentOrganization } = useAppContext();

  const { data: org, error, isLoading, isSuccess } = useGetOrganization(currentOrganization?.id);

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      setCurrentOrganization(org);
    }

    if (error) {
      navigate('/login');
    }
  }, [error, isSuccess, navigate, org, setCurrentOrganization]);

  useScrollToTop();

  return (
    <>
      {isLoading && <SplashScreen />}
      <AuthGuard>
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </Layout>
      </AuthGuard>
    </>
  );
}
