import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import { SplashScreen } from '@/components/loading-screen';
// import { Loading, RouteGuard } from '@/components';
// import { PermissionAction, PermissionHandle } from '@/constants';
import { PATHS } from '@/constants';

import App from '../App';

const Login = lazy(() => import('@/pages/Login'));

const Onboarding = lazy(() => import('@/pages/Onboarding'));

const Page500 = lazy(() => import('@/pages/500'));
const Page403 = lazy(() => import('@/pages/403'));
const Page404 = lazy(() => import('@/pages/404'));

const SignUp = lazy(() => import('@/pages/SignUp'));

const Vendors = lazy(() => import('@/pages/vendors'));
const VendorDetails = lazy(() => import('@/pages/vendors/details'));

const Engagements = lazy(() => import('@/pages/engagements'));

const Contacts = lazy(() => import('@/pages/contacts'));
const ContactDetails = lazy(() => import('@/pages/contacts/ContactDetails'));
// const Notifications = lazy(() => import('./notifications'));
const Prospects = lazy(() => import('@/pages/prospects'));
const ProspectsBoard = lazy(() => import('@/pages/prospects/KanbanBoard'));

const Upgrade = lazy(() => import('@/pages/upgrade'));
const Checkout = lazy(() => import('@/pages/upgrade/Checkout'));

const Users = lazy(() => import('@/pages/users'));
//
const ActivateInvitation = lazy(() => import('@/pages/activate'));

const Account = lazy(() => import('@/pages/account'));

const ResetPassword = lazy(() => import('@/pages/ResetPassword'));
const UpdatePassword = lazy(() => import('@/pages/UpdatePassword'));

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<App />}>
      <Route element={<Vendors />} path="/" />
      <Route element={<VendorDetails />} path=":id" />

      <Route element={<Prospects />} path={PATHS.prospects} />
      <Route element={<ProspectsBoard />} path={`${PATHS.prospects}/:id`} />

      <Route element={<Engagements />} path={PATHS.engagements} />

      <Route element={<Contacts />} path={PATHS.contacts} />
      <Route element={<ContactDetails />} path={`${PATHS.contacts}/:id`} />

      <Route element={<Users />} path={PATHS.users} />

      <Route element={<Onboarding />} path={PATHS.onboarding} />

      <Route element={<Account />} path={PATHS.account} />

      <Route element={<Upgrade />} path={PATHS.upgrade} />
      <Route element={<Checkout />} path={`${PATHS.upgrade}/:id`} />

      <Route element={<Page403 />} path="403" />
      <Route element={<Page404 />} path="404" />
      <Route element={<Page500 />} path="500" />

      <Route element={<Page404 />} path="*" />
      {/* <Route element={<Notifications />} path="notifications" /> */}
      {/* <Route */}
      {/*  element={ */}
      {/*    <RouteGuard action={PermissionAction.LIST} handle={PermissionHandle.USERS}> */}
      {/*      <Users /> */}
      {/*    </RouteGuard> */}
      {/*  } */}
      {/*  path="users" */}
      {/* /> */}
    </Route>

    <Route element={<ActivateInvitation />} path={`${PATHS.acceptInvitation}/:id`} />
    <Route
      element={
        <Suspense fallback={<SplashScreen />}>
          <Login />
        </Suspense>
      }
      path={PATHS.login}
    />

    <Route
      element={
        <Suspense fallback={<SplashScreen />}>
          <SignUp />
        </Suspense>
      }
      path={PATHS.signUp}
    />

    <Route
      element={
        <Suspense fallback={<SplashScreen />}>
          <ResetPassword />
        </Suspense>
      }
      path={PATHS.resetPassword}
    />
    <Route
      element={
        <Suspense fallback={<SplashScreen />}>
          <UpdatePassword />
        </Suspense>
      }
      path={`${PATHS.resetPassword}/:token`}
    />

    <Route element={<Page404 />} path="*" />
  </Routes>
);

export default AppRoutes;
