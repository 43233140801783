import { useEffect } from 'react';

const KeyListenerProvider = ({
  action,
  children,
}: {
  action: () => void;
  children: React.ReactNode;
}) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.code === 'Escape') action();
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [action]);
  return <>{children}</>;
};

export default KeyListenerProvider;
