import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';

import FormProvider, { RHFTextField } from '@/components/hook-form';
import Iconify from '@/components/iconify';
import { PATHS } from '@/constants';
import { useCreateOrganization } from '@/hooks/useOrganizations';
import { useAppContext } from '@/providers';

export default function CreateOrganizationForm() {
  const { t } = useTranslation();

  const formMethods = useForm({
    defaultValues: {
      label: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        label: yup.string().required(t('forms.organizations.labelValidation')),
      }),
    ),
  });

  const { handleSubmit } = formMethods;

  const { isPending, mutate: createOrganization } = useCreateOrganization();

  const { setCurrentOrganization } = useAppContext();

  const navigate = useNavigate();

  const onSubmit = handleSubmit((values) => {
    createOrganization(values, {
      onSuccess: (org) => {
        setCurrentOrganization(org);
        navigate(PATHS.base);
      },
    });
  });

  return (
    <FormProvider methods={formMethods} onSubmit={onSubmit}>
      <Stack spacing={2}>
        <RHFTextField name="label" label={t('forms.organizations.label')} />
        <Stack direction="row" justifyContent="flex-end">
          <LoadingButton
            color="inherit"
            size="large"
            type="submit"
            variant="contained"
            loading={isPending}
            endIcon={<Iconify icon="eva:arrow-ios-forward-fill" />}
            sx={{ justifyContent: 'space-between', pl: 2, pr: 1.5 }}
          >
            {t('forms.organizations.submit')}
          </LoadingButton>
        </Stack>
      </Stack>
    </FormProvider>
  );
}
