import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Label from '@/components/label';
import { PATHS } from '@/constants';
import { useAppContext } from '@/providers';

// ----------------------------------------------------------------------

export default function NavUpgrade() {
  const { currentUser } = useAppContext();

  const fullName = useMemo(
    () => `${currentUser?.firstName} ${currentUser?.lastName}`,
    [currentUser],
  );

  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        px: 2,
        py: 5,
        textAlign: 'center',
      }}
    >
      <Stack alignItems="center">
        <Box sx={{ position: 'relative' }}>
          <Avatar alt={fullName} sx={{ width: 48, height: 48 }}>
            {currentUser?.firstName?.charAt(0).toUpperCase()}
          </Avatar>

          <Label
            color="success"
            variant="filled"
            sx={{
              top: -6,
              px: 0.5,
              left: 40,
              height: 20,
              position: 'absolute',
              borderBottomLeftRadius: 2,
              border: (theme) => `solid 2px ${theme.palette.background.default}`,
            }}
          >
            Free
          </Label>
        </Box>

        <Stack spacing={0.5} sx={{ mb: 2, mt: 1.5, width: 1 }}>
          <Typography variant="subtitle2" noWrap>
            {fullName}
          </Typography>

          <Typography variant="body2" noWrap sx={{ color: 'text.disabled' }}>
            {currentUser?.email}
          </Typography>
        </Stack>

        <Button onClick={() => navigate(PATHS.upgrade)} variant="contained">
          {t('buttons.upgrade')}
        </Button>
      </Stack>
    </Stack>
  );
}
